import { Radio } from "antd";
import Title from "antd/lib/typography/Title";

export const RadioBox = ({
  title,
  defaultValue,
  options,
  onChange,
}: {
  title?: string;
  defaultValue: string;
  options: { value: string; caption: string }[];
  onChange: (value: string) => void;
}) => {
  return (
    <>
      {title && <Title level={5}>{title}</Title>}
      <Radio.Group defaultValue={defaultValue} onChange={(value) => onChange(value.target.value)}>
        {options.map((option) => (
          <Radio value={option.value} style={{ display: "block" }}>
            {option.caption}
          </Radio>
        ))}
      </Radio.Group>
    </>
  );
};
