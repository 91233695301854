import { CmsButton } from "../common/ButtonComponents";
import { useTranslation } from "react-i18next";
import { Modal } from "antd";
import { useForm } from "react-hook-form";
import ControlledInput from "../form/ControlledInput";
import useRules from "../../hooks/useRules";

type SearchName = {
  name?: string;
};

export const CmsSearchSettingsModal = ({
  name,
  visible,
  onClose,
  onSave,
}: {
  name?: string;
  visible: boolean;
  onClose: () => void;
  onSave: (values: SearchName) => void;
}) => {
  const { t } = useTranslation();
  const { isValidName, required } = useRules();

  const {
    handleSubmit,
    control,
    formState: { isValid, isDirty, isSubmitting },
  } = useForm<SearchName>({
    mode: "onChange",
    values: { name: name },
  });

  return (
    <Modal
      width={400}
      title={"add / edit"}
      open={visible}
      onCancel={onClose}
      footer={[
        <CmsButton
          buttonType="cancel"
          key="cancel"
          style={{ marginRight: "8px" }}
          onClick={onClose}
        >
          {t("common:cancel")}
        </CmsButton>,
        <CmsButton
          buttonType="save"
          key="save"
          type="primary"
          disabled={!isValid || isSubmitting || !isDirty}
          onClick={handleSubmit(onSave)}
        >
          {t("common:save")}
        </CmsButton>,
      ]}
    >
      <ControlledInput
        control={control}
        label={t("properties:name")}
        name="name"
        canUpdate
        isLoading={false}
        rules={{
          ...required(t("properties:name")),
          ...isValidName(t("properties:name")),
        }}
      />
    </Modal>
  );
};
