import { Control, Controller, FieldPath, FieldValues } from "react-hook-form";
import { CmsFormItem } from "../common/FormComponents";
import { Checkbox } from "antd";

export default function ControlledCheckbox<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  control,
  name,
  label,
  isDisabled,
}: {
  control: Control<TFieldValues, any>;
  name: TName;
  label: string;
  isDisabled: boolean;
}) {
  return (
    <Controller
      control={control}
      name={name}
      disabled={isDisabled}
      render={({ field: { onChange, value, ref, name, disabled } }) => (
        <CmsFormItem label={label}>
          <Checkbox name={name} disabled={disabled} checked={value} onChange={onChange} ref={ref} />
        </CmsFormItem>
      )}
    />
  );
}
