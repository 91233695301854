import { DeleteOutlined, ExpandOutlined } from "@ant-design/icons";
import { Card, Empty, Popconfirm } from "antd";
import Meta from "antd/lib/card/Meta";
import update from "immutability-helper";
import * as _ from "lodash";
import * as React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Crop } from "react-image-crop";
import { Point } from "react-lasso-select/lib/helpers";
import { default as config } from "../../../Config";
import { useAuthorization } from "../../../hooks/useAuthorization";
import { Image } from "../../../models/data/Image";
import { SecurityFeatureSelection } from "../../../models/data/SecurityFeatureSelection";
import { SecurityFeature } from "../../../models/templates/SecurityFeature";
import { getLocalizedValue } from "../../../utilities/MultilingualHelper";
import { CmsPageLoader } from "../../common/PageComponents";
import { SecurityFeatureDetailsModal } from "./SecurityFeatureDetailsModal";
import "./SecurityFeatureList.scss";
import { SecurityFeatureSelectionEditor } from "./SecurityFeatureSelectionEditor";
import { useQuerySecurityFeaturesByIds } from "../../../queries/security-features/lists";

interface State {
  selectedFeatureIndex: number | null;
  securityFeatureSelections: SecurityFeatureSelection[] | null;
  showDetailsModal: boolean;
  showSelectionModal: boolean;
}

export const SecurityFeatureList = (props: {
  image: Image;
  securityFeatureSelections: SecurityFeatureSelection[];
  onChange: (securityFeatureSelections: SecurityFeatureSelection[]) => void;
  onShowRegion?: (shapeType: "RECTANGLE" | "POLYGON", crop: Crop, points?: Point[]) => void;
  onClearRegion?: () => void;
}) => {
  const { canUpdate } = useAuthorization("document");
  const { t } = useTranslation();

  const [state, setState] = useState<State>({
    selectedFeatureIndex: null,
    securityFeatureSelections: null,
    showDetailsModal: false,
    showSelectionModal: false,
  });
  const [ids, setIds] = useState<string[] | undefined>();

  const { data: securityFeatures, isLoading: isLoadingSecurityFeatures } =
    useQuerySecurityFeaturesByIds(ids);

  const getSecurityFeatureById = (id: string) =>
    securityFeatures?.find((securityFeature) => securityFeature.id === id);

  useEffect(() => {
    (async () => {
      const idsToFetch = _.uniq(
        (props.securityFeatureSelections || []).map((selection) => selection.securityFeatureId),
      ) as string[];

      setIds(idsToFetch);

      if (securityFeatures) {
        const orderedSecurityFeatures = _.sortBy(
          props.securityFeatureSelections,
          (securityFeatureSelection: { securityFeatureId: string; order: any }) => {
            return `${
              getSecurityFeatureById(securityFeatureSelection.securityFeatureId)?.name
            } ${securityFeatureSelection.order}`;
          },
        );

        setState((prevState) => ({
          ...prevState,
          securityFeatureSelections: orderedSecurityFeatures,
        }));
      }
    })();
  }, [props.securityFeatureSelections, securityFeatures]);

  const renderSecurityFeatureSelection = (
    securityFeatureSelection: SecurityFeatureSelection,
    index: number,
  ): React.ReactNode => {
    return (
      <Card
        key={securityFeatureSelection.id || index}
        hoverable
        style={{
          width: "200px",
          marginRight: "16px",
          marginBottom: "16px",
          borderRadius: "0",
        }}
        cover={
          <div
            style={{
              minHeight: "200px",
              minWidth: "200px",
              borderRadius: "0",
              lineHeight: "190px",
              textAlign: "center",
            }}
          >
            {securityFeatureSelection.images &&
            securityFeatureSelection.images.length > 0 &&
            securityFeatureSelection.images[0].fileId ? (
              <img
                src={`${config.apiUrl}file/${securityFeatureSelection.images[0].fileId}?imageSize=M`}
                alt="Security feature"
                draggable={false}
                style={{
                  borderRadius: "0",
                  maxWidth: "200px",
                  maxHeight: "200px",
                }}
              />
            ) : props.image.fileId != null &&
              (((securityFeatureSelection.width ?? 0) > 0 &&
                (securityFeatureSelection.height ?? 0) > 0) ||
                (securityFeatureSelection.points != null &&
                  securityFeatureSelection.points.length > 0)) ? (
              <img
                src={`${config.apiUrl}file/${props.image.fileId}?selectionId=${securityFeatureSelection.id}&imageSize=M`} //&timeStamp=${new Date().getTime()}
                alt="Security feature"
                style={{
                  borderRadius: "0",
                  maxWidth: "200px",
                  maxHeight: "200px",
                }}
              />
            ) : (
              <img src="/assets/images/keesing-icon-transparant-small.png" alt="Keesing" />
            )}
          </div>
        }
        onClick={() => {
          setState((prevState) => ({
            ...prevState,
            showDetailsModal: true,
            selectedFeatureIndex: index,
          }));
        }}
        onMouseEnter={() =>
          props.onShowRegion &&
          props.onShowRegion(
            securityFeatureSelection.shapeType,
            {
              unit: "%",
              x: securityFeatureSelection.x ?? 0,
              y: securityFeatureSelection.y ?? 0,
              width: securityFeatureSelection.width ?? 0,
              height: securityFeatureSelection.height ?? 0,
            },
            securityFeatureSelection.points,
          )
        }
        onMouseLeave={() => props.onClearRegion && props.onClearRegion()}
        actions={[
          canUpdate && (
            <ExpandOutlined
              key="edit"
              title={t("common:edit")}
              onClick={(e) => {
                e.stopPropagation();
                setState((prevState) => ({
                  ...prevState,
                  showSelectionModal: true,
                  selectedFeatureIndex: index,
                }));
              }}
            />
          ),
          canUpdate && (
            <Popconfirm
              title={t("common:confirmDelete")}
              onCancel={(e) => {
                e?.stopPropagation();
              }}
              onConfirm={(e) => {
                e?.stopPropagation();

                const features =
                  update(state.securityFeatureSelections, {
                    $splice: [[index, 1]],
                  }) ?? [];

                setState((prevState) => ({
                  ...prevState,
                  securityFeatureSelections: features,
                }));

                props.onChange(features);
              }}
              okText={t("common:yes")}
              cancelText={t("common:no")}
            >
              <DeleteOutlined
                key="delete"
                title={t("common:delete")}
                onClick={(e) => e.stopPropagation()}
              />
            </Popconfirm>
          ),
        ]}
      >
        <Meta
          style={{ minHeight: "54px" }}
          title={`${
            getSecurityFeatureById(securityFeatureSelection.securityFeatureId)?.name
          } ${securityFeatureSelection.order + 1}`}
        />
      </Card>
    );
  };

  return (
    <React.Fragment>
      <CmsPageLoader
        loading={isLoadingSecurityFeatures && !!securityFeatures}
        subTitle={t("common:loadingData")}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            flexWrap: "wrap",
          }}
        >
          {state.securityFeatureSelections && state.securityFeatureSelections.length > 0 ? (
            state.securityFeatureSelections.map((securityFeatureSelection, index) =>
              renderSecurityFeatureSelection(securityFeatureSelection, index),
            )
          ) : (
            <Empty
              style={{ width: "100%", marginTop: "25px" }}
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          )}
        </div>
        {state.showDetailsModal && securityFeatures && state.securityFeatureSelections && (
          <SecurityFeatureDetailsModal
            image={props.image}
            securityFeature={
              getSecurityFeatureById(
                state.securityFeatureSelections?.[state.selectedFeatureIndex as number]
                  .securityFeatureId,
              ) ?? new SecurityFeature()
            }
            securityFeatureSelection={
              state.securityFeatureSelections[state.selectedFeatureIndex as number]
            }
            onSave={(updatedSecurityFeatureSelection) => {
              const features = state.securityFeatureSelections;

              if (!features || features.length === 0) return;

              features[state.selectedFeatureIndex as number] = updatedSecurityFeatureSelection;

              props.onChange(features);

              setState((prevState) => ({
                ...prevState,
                showDetailsModal: false,
                selectedFeatureIndex: null,
              }));
            }}
            onClose={() => {
              setState((prevState) => ({
                ...prevState,
                showDetailsModal: false,
                selectedFeatureIndex: null,
              }));
            }}
          />
        )}
        {state.showSelectionModal && securityFeatures && state.securityFeatureSelections && (
          <SecurityFeatureSelectionEditor
            fileId={props.image.fileId as string}
            featureName={
              getLocalizedValue(
                getSecurityFeatureById(
                  state.securityFeatureSelections[state.selectedFeatureIndex as number]
                    .securityFeatureId,
                )?.caption,
              ) as string
            }
            selection={state.securityFeatureSelections[state.selectedFeatureIndex as number]}
            onClose={() =>
              setState((prevState) => ({
                ...prevState,
                showSelectionModal: false,
              }))
            }
            onSave={(selection) => {
              const features = update(state.securityFeatureSelections, {
                [state.selectedFeatureIndex as number]: { $set: selection },
              });

              features && props.onChange(features);

              setState((prevState) => ({
                ...prevState,
                showSelectionModal: false,
                selectedFeatureIndex: null,
              }));
            }}
          />
        )}
      </CmsPageLoader>
    </React.Fragment>
  );
};
