export const hasSameValues = (setA: Set<string>, setB: Set<string>): boolean => {
  for (const key of setA.values()) {
    if (!setB.has(key)) {
      return false;
    }
  }

  for (const key of setB.values()) {
    if (!setA.has(key)) {
      return false;
    }
  }

  return true;
};
