import { useQuery } from "@tanstack/react-query";
import { Country } from "../../models/reference_lists/Country";
import api from "../../services/api";
import { CONST_COUNTRIES } from "../../utilities/constants/query-contants";
import { CountryPayload } from "./country-types";

export const getCountries = (payload?: CountryPayload): Promise<Country[]> => {
  return api.get({
    path: CONST_COUNTRIES.plural,
    query: { ...payload },
  });
};

export const useQueryCountries = (payload?: CountryPayload) => {
  return useQuery<Country[]>([CONST_COUNTRIES.plural], () => getCountries(payload));
};
