import { useEffect, useState } from "react";
import { CmsPageLoader } from "../components/common/PageComponents";

const AuthBoundary = ({ children }: { children: JSX.Element }) => {
  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (sessionStorage.getItem("token") !== undefined && sessionStorage.getItem("token") !== "") {
      setLoaded(true);
    }
  }, [sessionStorage.getItem("token")]);

  if (!loaded) {
    return (
      <CmsPageLoader
        loading={true}
        key={"keycloak-pageloader"}
        title={"Authenticating."}
        subTitle={"On moment please"}
      />
    );
  }

  return children;
};

export default AuthBoundary;
