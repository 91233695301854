import api from "../../services/api";
import blobToFile from "js-file-download";
import { CONST_TRANSLATIONS } from "../../utilities/constants/query-contants";
import { useMutation } from "@tanstack/react-query";
import { Languages } from "../languages/types";
import { RcFile } from "antd/es/upload";
import { useNotification } from "../../hooks/useNotification";

export const getTranslation = (
  payload: Languages,
  headers?: Record<string, string>,
  isFile?: boolean,
  isUpload?: boolean,
): Promise<any> => {
  return api.get({
    path: `${CONST_TRANSLATIONS.plural}?sourceLanguage=${payload.source}&targetLanguage=${payload.target}`,
    headers: { ...headers },
    isFile: isFile ?? false,
    isUpload: isUpload ?? false,
  });
};

export const useExportTranslation = () =>
  useMutation((payload: Languages) => getTranslation(payload, { Accept: "text/csv" }, true), {
    onSuccess: (data) => {
      blobToFile(data, "Translation results.csv");
    },
  });

export const postTranslation = (payload: string | Blob | RcFile): Promise<any> => {
  return api.get({
    path: CONST_TRANSLATIONS.plural,
    body: payload,
    isFile: true,
    isUpload: true,
  });
};

export const useImportTranslation = () => {
  const { notifySuccess } = useNotification();

  return useMutation((payload: string | Blob | RcFile) => postTranslation(payload), {
    onSuccess: () => {
      notifySuccess("The translations import process was started succesfully.");
    },
  });
};
