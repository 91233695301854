import React, { useEffect, useState } from "react";
import { FieldValue } from "../../../models/data/FieldValue";
import { Button, Modal, Result, Steps, Table } from "antd";
import { useTranslation } from "react-i18next";
import { LeftOutlined, LoadingOutlined, RightOutlined } from "@ant-design/icons";
import styles from "./CopyModal.module.scss";
import { CmsSearch } from "../../common/PageComponents";
import Title from "antd/lib/typography/Title";
import { DocumentSeries } from "../../../models/data/Document";
import { FieldLink } from "../../../models/templates/FieldLink";
import Config from "../../../Config";
import { capitalizeFirstLetter } from "../../../utilities/StringHelper";
import { getField } from "../../../queries/fields/detail";
import { useQueryDocumentSeries } from "../../../queries/document-series/lists";
import { getDocumentSerie } from "../../../queries/document-series/detail";
import { useQueryDocumentTemplate } from "../../../queries/document-template/detail";

enum WizardSteps {
  ChooseDocument = 0,
  ChooseFields = 1,
  Summary = 2,
}

export default function FieldsCopyModal(props: {
  documentTemplateId: string;
  viewTemplateId: string;
  imageTemplateId: string;
  fields: Array<FieldValue>;
  onClose: () => void;
  onSave: (selections: Array<FieldValue>) => void;
}) {
  const { t } = useTranslation();

  const [wizard, setWizard] = useState<{
    currentStep: WizardSteps;
    filteredDocuments?: DocumentSeries[];
    selectedDocumentId?: string;
    fieldLinks: FieldLink[];
    selectedFieldLinks: string[];
    selectedFieldValues: FieldValue[];
    documentSearchString?: string;
  }>({
    currentStep: WizardSteps.ChooseDocument,
    filteredDocuments: [],
    selectedDocumentId: undefined,
    fieldLinks: [],
    selectedFieldLinks: [],
    selectedFieldValues: [],
    documentSearchString: undefined,
  });

  const defaultLanguage = Config.language;

  const { data: documents, isLoading: isLoadingDocuments } = useQueryDocumentSeries({
    query: { documentTemplateId: props.documentTemplateId },
  });
  const { data: documentTemplate, isLoading: isLoadingDocumentTemplate } = useQueryDocumentTemplate(
    props.documentTemplateId,
  );

  const search = (text: string) => {
    if (!isLoadingDocuments && !!documents) {
      const search = text.toLowerCase().replace(" ", "_");
      const filteredDocuments = documents.filter((document) => {
        const name = document.code && document.code.toLowerCase();
        return name && name.includes(search);
      });

      setWizard((prevState) => ({
        ...prevState,
        documentSearchString: search,
        filteredDocuments: filteredDocuments,
      }));
    }
  };

  useEffect(() => {
    if (documents) {
      setWizard((prevState) => ({
        ...prevState,
        filteredDocuments: documents,
      }));
    }
  }, [documents]);

  useEffect(() => {
    if (wizard.selectedDocumentId) {
      (async () => {
        const fieldLinks = documentTemplate?.viewTemplates
          .find((x) => x.id === props.viewTemplateId)
          ?.imageTemplates.find((y) => y.id === props.imageTemplateId)?.fieldLinks;

        if (!fieldLinks) return;

        const fieldLinksCaptioned = (await Promise.all(
          fieldLinks.map(async (fieldLink) => {
            if (fieldLink.fieldId) {
              const field = await getField(fieldLink.fieldId);
              return {
                ...fieldLink,
                fieldName: capitalizeFirstLetter(field.caption[defaultLanguage]),
              };
            }
          }),
        )) as FieldLink[];

        setWizard((prevState) => ({
          ...prevState,
          fieldLinks: fieldLinksCaptioned,
          selectedFieldLinks: fieldLinks.map((fieldLink) => fieldLink.fieldId) as string[],
        }));
      })();
    }
  }, [
    wizard.selectedDocumentId,
    props.documentTemplateId,
    props.imageTemplateId,
    props.viewTemplateId,
  ]);

  const importFieldValues = async () => {
    if (!wizard.selectedDocumentId) return;

    const values = await getDocumentSerie(wizard.selectedDocumentId);

    const views = values.views.find((view) => view.viewTemplateId === props.viewTemplateId);

    let selectedDocumentFieldValues = [] as Array<FieldValue>;

    if (views) {
      const filteredViews = views.images?.find(
        (image) => image.imageTemplateId === props.imageTemplateId,
      );

      if (filteredViews && filteredViews.fieldValues) {
        selectedDocumentFieldValues = filteredViews.fieldValues;
      }
    }

    const selectedFieldLinks = wizard.fieldLinks.filter(
      (field) => field.fieldId && wizard.selectedFieldLinks.includes(field.fieldId),
    );

    const selectedFieldValues = selectedDocumentFieldValues.filter((fieldValue) =>
      selectedFieldLinks.map(
        (fieldLink) => fieldValue.fieldLinkId && fieldLink.id?.includes(fieldValue.fieldLinkId),
      ),
    );

    const returnArray = selectedFieldValues.map((selectedField) => ({
      imageId: selectedField.imageId,
      fieldLinkId: selectedField.fieldLinkId,
      height: selectedField.height,
      width: selectedField.width,
      shapeType: selectedField.shapeType,
      x: selectedField.x,
      y: selectedField.y,
      points: selectedField.points,
    })) as FieldValue[];

    props.onSave(returnArray);
  };

  return (
    <Modal
      width={1000}
      title={t("common:copyFields")}
      open={true}
      closable={true}
      onCancel={props.onClose}
      footer={null}
    >
      <div className={styles["wizard"]}>
        <Steps current={wizard.currentStep} direction="vertical" className={styles["stepper"]}>
          <Steps.Step title={t("common:source")} />
          <Steps.Step title={t("entities:fields")} />
          <Steps.Step title={t("common:summary")} />
        </Steps>
        <div className={styles["step"]}>
          {wizard.currentStep === WizardSteps.ChooseDocument ? (
            <React.Fragment>
              <div className={styles["step-content"]}>
                <Title level={3}>{t("common:source")}</Title>
                <div className={styles["step-text"]}>{t("texts:copyModalSelectDocument")}</div>
                {!isLoadingDocuments ? (
                  <React.Fragment>
                    <div className={styles["search"]}>
                      <CmsSearch
                        key="search"
                        value={wizard.documentSearchString}
                        onChange={search}
                      />
                    </div>
                    <Table
                      bordered
                      size={"small"}
                      dataSource={wizard.filteredDocuments}
                      rowKey="id"
                      rowSelection={{
                        type: "radio",
                        selectedRowKeys: wizard.selectedDocumentId
                          ? [wizard.selectedDocumentId]
                          : [],
                      }}
                      pagination={{ pageSize: 6 }}
                      onRow={(document) => ({
                        onClick: () =>
                          setWizard((prevState) => ({
                            ...prevState,
                            selectedDocumentId: document.id,
                          })),
                      })}
                      columns={[
                        {
                          title: t("common:document"),
                          dataIndex: "code",
                          onFilter: () => {
                            return true;
                          },
                        },
                      ]}
                    />
                  </React.Fragment>
                ) : (
                  <Result icon={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
                )}
              </div>

              <div className={styles["button-bar"]}>
                <Button
                  className={"button-next"}
                  type="primary"
                  icon={<RightOutlined />}
                  disabled={!wizard.selectedDocumentId}
                  onClick={() =>
                    setWizard((prevState) => ({
                      ...prevState,
                      currentStep: WizardSteps.ChooseFields,
                    }))
                  }
                >
                  {t("common:next")}
                </Button>
              </div>
            </React.Fragment>
          ) : null}

          {wizard.currentStep === WizardSteps.ChooseFields ? (
            <React.Fragment>
              <div className={styles["step-content"]}>
                <Title level={3}>{t("entities:fields")}</Title>
                <div className={styles["step-text"]}>{t("texts:copyModalSelectFields")}</div>
                {!isLoadingDocumentTemplate ? (
                  <React.Fragment>
                    <Table
                      bordered
                      size={"small"}
                      dataSource={wizard.fieldLinks}
                      rowKey="fieldId"
                      rowSelection={{
                        type: "checkbox",
                        selectedRowKeys: wizard.selectedFieldLinks,
                        onChange: (selectedRowKeys) =>
                          setWizard((prevState) => ({
                            ...prevState,
                            selectedFieldLinks: selectedRowKeys as string[],
                          })),
                      }}
                      pagination={{ pageSize: 6 }}
                      columns={[
                        {
                          title: t("common:name"),
                          dataIndex: "fieldName",
                          onFilter: () => {
                            return true;
                          },
                        },
                      ]}
                    />
                  </React.Fragment>
                ) : (
                  <Result icon={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
                )}
              </div>

              <div className={styles["button-bar"]}>
                <Button
                  type="primary"
                  icon={<LeftOutlined />}
                  onClick={() =>
                    setWizard((prevState) => ({
                      ...prevState,
                      currentStep: WizardSteps.ChooseDocument,
                      selectedFieldValues: [],
                    }))
                  }
                >
                  {t("common:previous")}
                </Button>

                <Button
                  className={"button-next"}
                  type="primary"
                  icon={<RightOutlined />}
                  disabled={!wizard.selectedDocumentId}
                  onClick={() =>
                    setWizard((prevState) => ({
                      ...prevState,
                      currentStep: WizardSteps.Summary,
                    }))
                  }
                >
                  {t("common:next")}
                </Button>
              </div>
            </React.Fragment>
          ) : null}

          {wizard.currentStep === WizardSteps.Summary ? (
            <React.Fragment>
              <div className={styles["step-content"]}>
                <Title level={3}>{t("common:summary")}</Title>
                <div className={styles["step-text"]}>{t("texts:copyModalSummary")}</div>
                <React.Fragment>
                  <Table
                    bordered
                    size={"small"}
                    dataSource={wizard.fieldLinks.filter(
                      (field) => field.fieldId && wizard.selectedFieldLinks.includes(field.fieldId),
                    )}
                    rowKey="fieldId"
                    pagination={{ pageSize: 6 }}
                    columns={[
                      {
                        title: t("common:name"),
                        dataIndex: "fieldName",
                        onFilter: () => {
                          return true;
                        },
                      },
                    ]}
                  />
                </React.Fragment>
              </div>

              <div className={styles["button-bar"]}>
                <Button
                  type="primary"
                  icon={<LeftOutlined />}
                  onClick={() =>
                    setWizard((prevState) => ({
                      ...prevState,
                      currentStep: WizardSteps.ChooseFields,
                      selectedFieldValues: [],
                    }))
                  }
                >
                  {t("common:previous")}
                </Button>

                <Button className={"button-next"} type="primary" onClick={importFieldValues}>
                  {t("common:import")}
                </Button>
              </div>
            </React.Fragment>
          ) : null}
        </div>
      </div>
    </Modal>
  );
}
