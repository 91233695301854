import { useEffect } from "react";
import dayjs, { Dayjs } from "dayjs";
import { useCmsContext } from "../context/app/CmsContext";
import { QueryStringParams } from "../queries/document-series/document-series-type";

export const useDocumentFilter = (): {
  changeFilterPanel: (visible: boolean) => void;
  saveFilters: (filter: QueryStringParams, sessionKey?: string) => void;
  restoreFilters: (sessionKey?: string) => QueryStringParams | undefined;
  resetFilters: (sessionKey?: string) => void;
  disableBefore: (current: Dayjs, last?: string) => boolean;
  disabledAfter: (current: Dayjs, last?: string) => boolean;
} => {
  const context = useCmsContext();

  const changeFilterPanel = (visible: boolean) => {
    context?.setDocumentSeriesFilterPanel(visible);
    localStorage.setItem("setFilterDocumentSeriesPanel", visible.toString());
  };

  const saveFilters = (filter: QueryStringParams, sessionKey?: string): void =>
    sessionStorage.setItem(sessionKey ?? "queryFilters", JSON.stringify(filter));

  const restoreFilters = (sessionKey?: string): QueryStringParams | undefined => {
    const filters = sessionStorage.getItem(sessionKey ?? "queryFilters");
    if (filters) {
      return JSON.parse(filters);
    }
  };

  const resetFilters = (sessionKey?: string) => {
    sessionStorage.removeItem(sessionKey ?? "queryFilters");
  };

  const disableBefore = (current: Dayjs, last?: string): boolean => {
    return (current && last && current < dayjs(last).endOf("day")) as boolean;
  };

  const disabledAfter = (current: Dayjs, last?: string): boolean => {
    return (current && last && current > dayjs(last).endOf("day")) as boolean;
  };

  useEffect(() => {
    const getFilterPanel = localStorage.getItem("setFilterDocumentSeriesPanel");
    if (getFilterPanel != null) {
      context?.setDocumentSeriesFilterPanel(JSON.parse(getFilterPanel) === true);
    }
  }, []);

  return {
    changeFilterPanel,
    saveFilters,
    restoreFilters,
    resetFilters,
    disableBefore,
    disabledAfter,
  };
};
