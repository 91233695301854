import { Button, Checkbox, Form, Input, Layout, Modal, Select, TreeSelect } from "antd";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AttributeLink } from "../../../models/templates/AttributeLink";
import { convertGroupsForTreeSelect } from "../../../utilities/GroupHelper";
import { validateRequired } from "../../../utilities/Validators";
import { CmsModalForm } from "../../common/FormComponents";
import { MultilingualInput } from "../../common/MultilingualInput";
import { CmsPageLoader } from "../../common/PageComponents";
import { GroupType } from "../../../queries/groups/group-types";
import { useQueryGroups } from "../../../queries/groups/lists";
import { useQueryAttributes } from "../../../queries/attributes/lists";
import { getAttribute } from "../../../queries/attributes/detail";

export const AttributeLinkDetailsModal = (props: {
  attributeLink: AttributeLink;
  onClose(): void;
  onSave(attributeLink: AttributeLink): void;
}) => {
  const { t } = useTranslation();
  const [selectedGroupId, setSelectedGroupId] = useState<string | undefined>(undefined);

  const { data: attributeGroups, isLoading: isLoadingAttributeGroups } = useQueryGroups(
    GroupType.Attribute,
  );
  const { data: attributes, isLoading: isLoadingAttributes } = useQueryAttributes({
    groupId: selectedGroupId,
  });

  const setGroup = async (attributeId: string) => {
    const attribute = await getAttribute(attributeId);
    const groupId = attribute.groupId;
    setSelectedGroupId(groupId);
  };

  useEffect(() => {
    if (props.attributeLink) {
      if (props.attributeLink.attributeId) {
        setGroup(props.attributeLink.attributeId);
      } else {
        setSelectedGroupId(undefined);
      }
    }
  }, [props.attributeLink, getAttribute]);

  const validate = (attributeLink: AttributeLink) => {
    const errors: any = {};

    const attributeIdResult = validateRequired(
      attributeLink.attributeId as string,
      t("entities:attribute"),
    );
    if (attributeIdResult != null) {
      errors.attributeId = attributeIdResult;
    }

    return errors;
  };

  return (
    <Formik
      initialValues={props.attributeLink}
      enableReinitialize={true}
      validate={validate}
      onSubmit={(attributeLink) => {
        props.onSave(attributeLink);
      }}
    >
      {(formikProps) => {
        const { values, handleChange, handleSubmit, setFieldValue, errors, isValid } = formikProps;

        return (
          <Modal
            width={700}
            title={t("entities:attribute")}
            visible={true}
            footer={[
              <Button id="btnCancel" key="cancel" onClick={props.onClose}>
                {t("common:cancel")}
              </Button>,
              <Button
                id="btnSave"
                key="save"
                type="primary"
                disabled={!isValid}
                onClick={() => {
                  handleSubmit();
                }}
              >
                {t("common:save")}
              </Button>,
            ]}
            onCancel={props.onClose}
          >
            <CmsPageLoader loading={isLoadingAttributeGroups} subTitle={t("common:loadingData")}>
              <Layout style={{ background: "#fff" }}>
                <div key="attributeLinkDetails">
                  <CmsModalForm>
                    <Form.Item
                      label={t("entities:attribute")}
                      validateStatus={!errors.attributeId ? "success" : "error"}
                      help={errors.attributeId}
                      required={true}
                    >
                      <React.Fragment>
                        <TreeSelect
                          id="groupId"
                          treeDefaultExpandAll={true}
                          style={{ width: "40%" }}
                          placeholder={t("properties:group")}
                          onChange={(groupId) => {
                            setFieldValue("groupId", groupId);
                            setFieldValue("attributeId", undefined);
                            setSelectedGroupId(groupId);
                          }}
                          value={selectedGroupId}
                          treeData={convertGroupsForTreeSelect(attributeGroups ?? [])}
                        />
                        &nbsp;
                        <Select
                          showSearch
                          id="attributeId"
                          style={{ width: "58%" }}
                          placeholder={t("entities:attribute")}
                          loading={isLoadingAttributes}
                          disabled={isLoadingAttributes}
                          filterOption={(input, option) =>
                            option?.props.children
                              .toString()
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          onChange={(attributeId: string) => {
                            const attribute = attributes?.find(
                              (attribute) => attribute.id === attributeId,
                            );
                            if (attribute) {
                              setFieldValue("attributeName", attribute.name);
                            }
                            setFieldValue("attributeId", attributeId);
                          }}
                          value={values.attributeId}
                        >
                          {attributes?.map((value, index) => (
                            <Select.Option key={`${index}`} value={value.id}>
                              {value.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </React.Fragment>
                    </Form.Item>
                    <Form.Item
                      label={t("properties:caption")}
                      validateStatus={!errors.caption ? "success" : "error"}
                      help={errors.caption}
                    >
                      <MultilingualInput
                        mlData={values.caption}
                        onChange={(e) => setFieldValue("caption", e)}
                      />
                    </Form.Item>
                    <Form.Item label={t("properties:defaultValue")}>
                      <Input
                        placeholder={t("properties:defaultValue")}
                        maxLength={50}
                        id="defaultValue"
                        value={values.defaultValue}
                        onChange={handleChange}
                      />
                    </Form.Item>
                    <Form.Item label={t("properties:exportable")}>
                      <Checkbox
                        id="exportable"
                        checked={values.exportable}
                        onChange={(e) => setFieldValue("exportable", e.target.checked)}
                      />
                    </Form.Item>
                    <Form.Item label={t("common:filterable")}>
                      <Checkbox
                        id="filterable"
                        checked={values.filterable}
                        onChange={(e) => setFieldValue("filterable", e.target.checked)}
                      />
                    </Form.Item>
                    <Form.Item label={t("properties:required")}>
                      <Checkbox
                        id="isRequired"
                        checked={values.isRequired}
                        onChange={(e) => setFieldValue("isRequired", e.target.checked)}
                      />
                    </Form.Item>
                  </CmsModalForm>
                </div>
              </Layout>
            </CmsPageLoader>
          </Modal>
        );
      }}
    </Formik>
  );
};
