import api from "../../services/api";
import { useNotification } from "../../hooks/useNotification";
import { useMutation } from "@tanstack/react-query";
import { FilePayload, FileResponse } from "./file-types";
import { CONST_FILES } from "../../utilities/constants/query-contants";

export const postFile = (payload: FilePayload): Promise<FileResponse> => {
  const formData = new FormData();
  formData.append("file", payload.file);

  return api.post({
    path: CONST_FILES.singular,
    body: formData,
    isFile: true,
    isUpload: true,
  });
};

export const useUploadFile = () => {
  const { notifySuccess } = useNotification();

  return useMutation((payload: FilePayload) => postFile(payload), {
    onSuccess: () => {
      notifySuccess("The file import process was started succesfully.");
    },
  });
};
