import { Modal, Select } from "antd";
import { DocumentSeries } from "../../../models/data/Document";
import { useTranslation } from "react-i18next";
import { CmsForm, CmsFormLayout, CmsSelect } from "../../common/FormComponents";
import { useAuthorization } from "../../../hooks/useAuthorization";
import { ActionType } from "../../../models/data/DC/ActionType";
import { Notification } from "../../../models/data/DC/Notification";
import { NotificationType } from "../../../models/data/DC/NotificationType";
import { CmsButton } from "../../common/ButtonComponents";

export const NotificationsModal = ({
  document,
  notification,
  onCancel,
  onChange,
  onSave,
  onSubmit,
}: {
  document: DocumentSeries;
  notification: Notification;
  onCancel: () => void;
  onChange: (notification: Notification) => void;
  onSave: () => void;
  onSubmit: () => void;
}) => {
  const { t } = useTranslation();
  const { canUpdate } = useAuthorization("document");

  return (
    <Modal
      width={400}
      title={t("common:createDcNotification", {
        for: document?.code,
      })}
      open={true}
      closable={true}
      onCancel={onCancel}
      footer={null}
    >
      <div>
        <div>
          <CmsForm {...CmsFormLayout.twocolumn}>
            <CmsSelect
              readOnly={!canUpdate}
              id="actionType"
              label={t("properties:actionType")}
              placeholder={t("properties:actionType")}
              allowClear={false}
              value={notification.action}
              onChange={(action: ActionType) => onChange({ ...notification, action })}
            >
              <Select.Option value={ActionType.NotSpecified}>
                {t("notifications:notSpecified")}
              </Select.Option>
              <Select.Option value={ActionType.DocumentEdited}>
                {t("notifications:documentEdited")}
              </Select.Option>
              <Select.Option value={ActionType.DocumentExpected}>
                {t("notifications:documentExpected")}
              </Select.Option>
              <Select.Option value={ActionType.DocumentOutOfCirculation}>
                {t("notifications:documentOutOfCirculation")}
              </Select.Option>
              <Select.Option value={ActionType.DocumentGoingOutOfCirculation}>
                {t("notifications:documentGoingOutOfCirculation")}
              </Select.Option>
              <Select.Option value={ActionType.DocumentPublished}>
                {t("notifications:documentPublished")}
              </Select.Option>
              <Select.Option value={ActionType.DocumentOutmoded}>
                {t("notifications:documentOutmoded")}
              </Select.Option>
            </CmsSelect>
            <CmsSelect
              readOnly={!canUpdate}
              id="notificationType"
              label={t("properties:notificationType")}
              placeholder={t("properties:notificationType")}
              allowClear={false}
              value={notification.type}
              onChange={(type: NotificationType) => onChange({ ...notification, type })}
            >
              <Select.Option value={NotificationType.NotSpecified}>
                {t("notifications:notSpecified")}
              </Select.Option>
              <Select.Option value={NotificationType.Info}>{t("notifications:info")}</Select.Option>
            </CmsSelect>
          </CmsForm>
        </div>
        <h5>{t("notifications:attention")}</h5>
        <p style={{ fontSize: "12px" }}>{t("notifications:saveMessage")}</p>
        <div style={{ display: "block" }}>
          <CmsButton buttonType="cancel" style={{ marginRight: "10px" }} onClick={onCancel} />
          <CmsButton
            buttonType="save"
            style={{ marginRight: "10px" }}
            disabled={
              notification.type === NotificationType.NotSpecified ||
              notification.action === ActionType.NotSpecified
            }
            onClick={onSave}
          />
          <CmsButton
            buttonType="send"
            style={{ marginRight: "10px" }}
            disabled={
              notification.type === NotificationType.NotSpecified ||
              notification.action === ActionType.NotSpecified
            }
            onClick={onSubmit}
          />
        </div>
      </div>
    </Modal>
  );
};
