import { Route, RouteProps } from "react-router-dom";
import { useAuthorization } from "../../hooks/useAuthorization";
import { useCmsContext } from "../../context/app/CmsContext";

export const PrivateRoute = (props: RouteProps & { section?: string }) => {
  const context = useCmsContext();
  const { canView } = useAuthorization(props.section);

  return context?.currentUser && (canView || !props.section) ? <Route {...props} /> : null;
};
