import { useQuery } from "@tanstack/react-query";
import { MaterialType } from "../../models/reference_lists/MaterialType";
import api from "../../services/api";
import { CONST_MATERIAL_TYPES } from "../../utilities/constants/query-contants";

export const getMaterialTypes = (): Promise<MaterialType[]> => {
  return api.get({
    path: CONST_MATERIAL_TYPES.plural,
  });
};

export const useQueryMaterialTypes = () => {
  return useQuery<MaterialType[]>([CONST_MATERIAL_TYPES.plural], () => getMaterialTypes());
};
