import { PlusOutlined } from "@ant-design/icons";
import { Button, Layout, Table, Tooltip } from "antd";
import * as React from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAuthorization } from "../../hooks/useAuthorization";
import { Group } from "../../models/templates/Group";
import { RowButtonSet } from "../common/RowButtonSet";
import { GroupManagerDetail } from "./GroupManagerDetail";
import { useCmsContext } from "../../context/app/CmsContext";
import { GroupType } from "../../queries/groups/group-types";
import { getEntityForAuthorization } from "../../utilities/helpers/group-helper";
import { useQueryGroups } from "../../queries/groups/lists";
import { getGroup, useDeleteGroup, useSaveGroup } from "../../queries/groups/detail";
import { CmsPageLoader } from "./PageComponents";

export const GroupManager = (props: { visible: boolean; groupType: GroupType }) => {
  const context = useCmsContext();
  const { t } = useTranslation();
  const { canCreate, canUpdate, canDelete, canView } = useAuthorization(
    getEntityForAuthorization(props.groupType),
  );
  const [state, setState] = React.useState<{
    editModalVisible: boolean;
    editingGroup: Group | null;
    editModalTitle: string;
    newGroupName: string | null;
  }>({
    editModalVisible: false,
    editingGroup: null,
    editModalTitle: "",
    newGroupName: null,
  });

  const {
    data: groups,
    isLoading: isLoadingGroups,
    refetch: refetchGroups,
  } = useQueryGroups(props.groupType);
  const { mutateAsync: saveGroup, isLoading: isSavingGroup } = useSaveGroup(props.groupType);
  const { mutateAsync: deleteGroup, isLoading: isDeletingGroup } = useDeleteGroup(props.groupType);

  const createTableData = (groups: Group[]) => {
    if (groups.length === 0) {
      return null;
    } else {
      return groups.map((group) => ({
        key: group.id,
        name: group.name,
        children: createTableData(group.children ?? []),
      }));
    }
  };

  useEffect(() => {
    if (state.newGroupName) {
      context?.setDuplicateErrorMessage(t("errors:duplicateGroup", { name: state.newGroupName }));
    }
  }, [state.newGroupName, t]);

  const addGroup = async () => {
    setState((prevState) => ({
      ...prevState,
      editingGroup: {} as Group,
      editModalVisible: true,
      editModalTitle: `${t("common:addGroup")}`,
    }));

    await refetchGroups();
  };

  const editGroup = async (id: string) => {
    const group = await getGroup(props.groupType, id);
    setState((prevState) => ({
      ...prevState,
      editingGroup: group,
      editModalVisible: true,
      editModalTitle: `${t("common:editGroup")}`,
    }));

    await refetchGroups();
  };

  const addChildGroup = async (id: string) => {
    const group = {} as Group;
    group.parentId = id;

    setState((prevState) => ({
      ...prevState,
      editingGroup: group,
      editModalVisible: true,
      editModalTitle: `${t("common:addGroup")}`,
    }));

    await refetchGroups();
  };

  const onSaveGroup = async (group: Group) => {
    setState((prevState) => ({
      ...prevState,
      newGroupName: group.name as string,
    }));

    await saveGroup(group);

    setState((prevState) => ({
      ...prevState,
      editingGroup: null,
      editModalVisible: false,
    }));

    await refetchGroups();
  };

  const onDeleteGroup = async (id: string) => {
    await deleteGroup(id);

    setState((prevState) => ({
      ...prevState,
      editingGroup: null,
      editModalVisible: false,
    }));

    await refetchGroups();
  };

  if (isLoadingGroups || !groups || isDeletingGroup || isSavingGroup) {
    return (
      <CmsPageLoader
        loading={true}
        key={"keycloak-pageloader"}
        title={t("common:loadingData")}
        subTitle={t("common:pleaseHold")}
      />
    );
  }

  return (
    <Layout style={{ minWidth: "500px", background: "#fff" }}>
      <Table
        showHeader={true}
        dataSource={groups?.map((group) => ({
          key: group.id,
          name: group.name,
          children: createTableData(group.children ?? []),
        }))}
        columns={[
          { title: t("properties:name"), dataIndex: "name", key: "name" },
          {
            title: canCreate ? (
              <Tooltip title={t("common:add")}>
                <Button
                  type="primary"
                  shape="circle"
                  icon={<PlusOutlined />}
                  style={{ margin: "0px 2px" }}
                  size="small"
                  onClick={() => addGroup()}
                />
              </Tooltip>
            ) : null,
            dataIndex: "actions",
            align: "right",
            render: (_, record: any) => (
              <RowButtonSet
                onEdit={
                  canUpdate || canView
                    ? () => {
                        editGroup(record.key);
                      }
                    : null
                }
                onAddChild={
                  canCreate
                    ? () => {
                        addChildGroup(record.key);
                      }
                    : null
                }
                onDelete={
                  canDelete &&
                  (record.children == null ||
                    record.children === undefined ||
                    record.children.length === 0)
                    ? () => {
                        onDeleteGroup(record.key);
                      }
                    : null
                }
              />
            ),
            width: "120px",
          },
        ]}
        pagination={false}
        expandRowByClick={true}
        indentSize={25}
      />

      <GroupManagerDetail
        visible={state.editModalVisible}
        title={state.editModalTitle}
        group={state.editingGroup}
        onClose={() =>
          setState((prevState) => ({
            ...prevState,
            editingGroup: null,
            editModalVisible: false,
          }))
        }
        onSave={(group) => onSaveGroup(group)}
      />
    </Layout>
  );
};
