import { Button, Popconfirm, Radio } from "antd";
import { RedoOutlined } from "@ant-design/icons";
import { CmsPageLoader, CmsTable } from "../common/PageComponents";
import { BulkImageUploadCategory } from "../../models/data/BulkImageUpload";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useAuthorization } from "../../hooks/useAuthorization";
import { RcFile } from "antd/es/upload";
import { BulkImageUploadResults } from "../../models/data/BulkImageUploadResults";
import {
  useProcessBulkImages,
  useQuerybulkImageUploads,
  useQuerybulkImageUploadsStatus,
} from "../../queries/bulk-image-uploads/list";
import { CONST_BULK_IMAGE_UPLOADS } from "../../utilities/constants/query-contants";
import {
  useDeleteAllBulkImageUpload,
  useDeleteBulkImageUpload,
  useSaveBulkImageUpload,
} from "../../queries/bulk-image-uploads/detail";

export const ImageUpload = () => {
  const { t } = useTranslation();
  const { isAdmin } = useAuthorization(CONST_BULK_IMAGE_UPLOADS.singular);
  const [getUploadCategory, setUploadCategory] = useState<BulkImageUploadCategory>(
    BulkImageUploadCategory.MultiSidedDocuments,
  );
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [getUploadResults, setUploadResults] = useState<BulkImageUploadResults[]>();

  const {
    data: bulkImageUploads,
    isLoading: isLoadingBulkImageUploads,
    refetch: refetchBulkImageUploads,
  } = useQuerybulkImageUploads();
  const {
    data: status,
    isLoading: isLoadingStatus,
    refetch: refetchStatus,
  } = useQuerybulkImageUploadsStatus();
  const { mutateAsync: process } = useProcessBulkImages();
  const { mutateAsync: uploadBulkImage, isLoading: isUploadingBulkImage } =
    useSaveBulkImageUpload();
  const { mutateAsync: deleteBulkImage, isLoading: isDeletingBulkImage } =
    useDeleteBulkImageUpload();
  const { mutateAsync: deleteAllBulkImage, isLoading: isDeletingAllBulkImage } =
    useDeleteAllBulkImageUpload();

  useEffect(() => {
    const timer = setTimeout(async () => {
      await refetchStatus();
      setIsProcessing(status ?? false);
    }, 5000);

    if (!isProcessing) {
      refetchBulkImageUploads();
      return () => clearTimeout(timer);
    }
  }, [isProcessing]);

  const deleteImageUploadAndRefresh = async (id: string) => {
    const image = bulkImageUploads?.find((value) => value.fileName === id);
    if (image) {
      await deleteBulkImage({ category: image.uploadCategory, fileName: image.fileName });
    }
  };

  const handleFileEvent = async (e) => {
    const files = e.target.files as RcFile[];

    if (files.length > 0) {
      const result = await uploadBulkImage({ files, category: getUploadCategory });
      e.target.value = "";

      setUploadResults(result);
      await refetchBulkImageUploads();
    }
  };

  return (
    <>
      <CmsPageLoader
        loading={isProcessing}
        title={t("common:processingData")}
        subTitle={t("common:processingDataSub")}
        extra={
          <Popconfirm
            title={t("common:processingCancelMessage")}
            okText={t("common:yes")}
            cancelText={t("common:no")}
            onConfirm={async () => await deleteAllBulkImage()}
            disabled={!!bulkImageUploads && bulkImageUploads.length === 0}
          >
            <Button>{t("common:cancel")}</Button>
          </Popconfirm>
        }
      >
        <Radio.Group value={getUploadCategory} onChange={(e) => setUploadCategory(e.target.value)}>
          <Radio.Button value={BulkImageUploadCategory.MultiSidedDocuments}>
            {t(`entities:multiSidedDocuments`)}
          </Radio.Button>
          <Radio.Button value={BulkImageUploadCategory.DoubleSidedDocuments}>
            {t(`entities:doubleSidedDocuments`)}
          </Radio.Button>
          <Radio.Button value={BulkImageUploadCategory.Banknotes}>
            {t(`entities:banknotes`)}
          </Radio.Button>
          <Radio.Button value={BulkImageUploadCategory.Counterfeits}>
            {t(`entities:counterfeits`)}
          </Radio.Button>
        </Radio.Group>

        <div style={{ height: "200px", marginBottom: "20px" }}>
          <form id="file-image-upload-form">
            <input
              id="file-image-upload-input"
              type="file"
              multiple
              accept="image/jpeg,.zip"
              onChange={handleFileEvent}
              style={{
                padding: 40,
                marginBottom: 10,
                marginTop: 10,
                border: "1px solid lightgrey",
                cursor: "pointer",
                borderRadius: 5,
              }}
            />
          </form>
          <p>{t("common:bulkImageUploadDescription")}</p>
        </div>
        <h3 style={{ marginBottom: 20 }}>
          Ready for processing{" "}
          <Popconfirm
            title={t("common:confirmProcess")}
            okText={t("common:yes")}
            cancelText={t("common:no")}
            onConfirm={async () => {
              await process();
              setIsProcessing(true);
            }}
            disabled={!!bulkImageUploads && bulkImageUploads.length === 0}
          >
            <Button
              key="new"
              type="primary"
              icon={<RedoOutlined />}
              size="small"
              disabled={!!bulkImageUploads && bulkImageUploads.length === 0}
            >
              {t("common:process")}
            </Button>
          </Popconfirm>
        </h3>

        <CmsTable
          loading={
            isLoadingBulkImageUploads ||
            isLoadingStatus ||
            isDeletingBulkImage ||
            isDeletingAllBulkImage ||
            isUploadingBulkImage
          }
          dataSource={bulkImageUploads ?? []}
          rowKey="fileName"
          columns={[
            {
              title: t("properties:filename"),
              dataIndex: "fileName",
            },
            {
              title: t("properties:code"),
              dataIndex: "documentCode",
            },
            {
              title: t("properties:connectedTo"),
              dataIndex: "filePath",
              render: (values) => values.join(" > "),
            },
            {
              title: t("properties:category"),
              dataIndex: "uploadCategory",
            },
          ]}
          onDelete={isAdmin ? async (id) => await deleteImageUploadAndRefresh(id) : undefined}
        />
        {getUploadResults && (
          <>
            <h3>Upload results</h3>
            <CmsTable
              loading={isLoadingBulkImageUploads}
              dataSource={getUploadResults}
              rowKey="fileName"
              columns={[
                {
                  title: t("properties:filename"),
                  dataIndex: "fileName",
                },
                {
                  title: t("properties:category"),
                  dataIndex: "uploadCategory",
                },
                {
                  title: t("properties:result"),
                  dataIndex: "uploaded",
                  render: (value) =>
                    value ? (
                      t("properties:success")
                    ) : (
                      <span style={{ color: "red" }}>{t("properties:failed")}</span>
                    ),
                },
              ]}
            />
          </>
        )}
      </CmsPageLoader>
    </>
  );
};
