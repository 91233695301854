import { EditOutlined } from "@ant-design/icons";
import { Button, Drawer, Layout } from "antd";
import * as React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useAuthorization } from "../../../hooks/useAuthorization";
import { CmsCreateButton } from "../../common/ButtonComponents";
import { GroupManager } from "../../common/GroupManager";
import { CmsContent, CmsPageHeader, CmsSider, CmsTable } from "../../common/PageComponents";
import { ITreeViewItem, TreeView } from "../../common/TreeView";
import { useQueryDocumentTemplateGroups } from "../../../queries/document-template-groups/lists";
import { useQueryDocumentTemplates } from "../../../queries/document-template/lists";
import { useDeleteDocumentTemplate } from "../../../queries/document-template/detail";
import { useCmsContext } from "../../../context/app/CmsContext";
import { GroupType } from "../../../queries/groups/group-types";

export const DocumentTemplateList = () => {
  const { t } = useTranslation();
  const { canCreate, canUpdate, canDelete, canView } = useAuthorization("documentTemplate");
  const { push } = useHistory();
  const context = useCmsContext();
  const [state, setState] = useState<{
    selectedGroup?: string;
    expandedGroups?: string[];
    groupsManagerVisible: boolean;
  }>({
    selectedGroup: sessionStorage.getItem("currentDocumentTemplateGroup") ?? undefined,
    expandedGroups: undefined,
    groupsManagerVisible: false,
  });

  const {
    data: documentTemplates,
    isLoading: isLoadingDocumentTemplates,
    refetch: refetchDocumentTemplates,
  } = useQueryDocumentTemplates(state.selectedGroup);
  const { mutateAsync: deleteDocumentTemplate, isLoading: isDeletingDocumentTemplate } =
    useDeleteDocumentTemplate();
  const {
    data: documentTemplateGroups,
    isLoading: isLoadingDocumentTemplateGroups,
    refetch: refetchDocumentTemplateGroups,
  } = useQueryDocumentTemplateGroups();

  useEffect(() => {
    if (documentTemplates) {
      const sessionKeys = sessionStorage.getItem("documentTemplateGroupExpandedKeys");
      const keys = sessionKeys ? sessionKeys.split(",") : [];
      setState((prevState) => ({ ...prevState, expandedGroups: keys }));
    }
  }, [documentTemplates]);

  React.useEffect(() => {
    if (state.selectedGroup) {
      sessionStorage.setItem("currentDocumentTemplateGroup", state.selectedGroup);
    }

    if (state.expandedGroups) {
      sessionStorage.setItem("documentTemplateGroupExpandedKeys", state.expandedGroups.join(","));
    }
  }, [state.selectedGroup, state.expandedGroups]);

  React.useEffect(() => {
    if (documentTemplates) {
      context?.setDuplicateErrorMessage(t("errors:documentTemplateLinked"));
    }
  }, [documentTemplates, t]);

  const isLoadingAny =
    isLoadingDocumentTemplates || isDeletingDocumentTemplate || isLoadingDocumentTemplateGroups;

  return (
    <React.Fragment>
      <CmsPageHeader
        title={t("entities:documentTemplates")}
        extra={[
          canCreate || canUpdate ? (
            <Button
              key="manage"
              icon={<EditOutlined />}
              onClick={() =>
                setState((prevState) => ({
                  ...prevState,
                  groupsManagerVisible: true,
                }))
              }
            >
              {t("common:manageGroups")}
            </Button>
          ) : null,
          canCreate ? (
            <CmsCreateButton key="create" onClick={() => push(`/document-template`)} />
          ) : null,
        ]}
      />
      <Layout>
        <CmsSider>
          <TreeView
            items={documentTemplateGroups as ITreeViewItem[]}
            selectedKey={state.selectedGroup}
            expandedKeys={state.expandedGroups}
            onExpand={(keys) => setState((prevState) => ({ ...prevState, expandedGroups: keys }))}
            onSelect={(groupId) =>
              setState((prevState) => ({
                ...prevState,
                selectedGroup: groupId,
              }))
            }
          />
        </CmsSider>
        <Layout>
          <CmsContent>
            <CmsTable
              loading={isLoadingAny}
              dataSource={documentTemplates ?? []}
              rowKey="id"
              columns={[{ title: t("properties:name"), dataIndex: "name" }]}
              onEdit={canUpdate || canView ? (id) => push(`/document-template/${id}`) : undefined}
              onDelete={
                canDelete
                  ? async (id) => {
                      await deleteDocumentTemplate(id);
                      await refetchDocumentTemplates();
                    }
                  : undefined
              }
            />
          </CmsContent>
        </Layout>
      </Layout>

      <Drawer
        placement="left"
        open={state.groupsManagerVisible}
        width={600}
        onClose={async () => {
          await refetchDocumentTemplates();
          await refetchDocumentTemplateGroups();
          setState((prevState) => ({
            ...prevState,
            groupsManagerVisible: false,
          }));
        }}
        title={t("common:manageGroups")}
      >
        <GroupManager visible={state.groupsManagerVisible} groupType={GroupType.DocumentTemplate} />
      </Drawer>
    </React.Fragment>
  );
};
