import React from "react";
import { useTranslation } from "react-i18next";
import { Prompt, useHistory, useParams } from "react-router-dom";
import { useAuthorization } from "../../../hooks/useAuthorization";
import { Entity } from "../../../models/data/Entity";
import { DocumentType } from "../../../models/reference_lists/DocumentType";
import { CmsBackButton, CmsSaveButton } from "../../common/ButtonComponents";
import { CmsForm } from "../../common/FormComponents";
import { CmsPageHeader, CmsPageLoader } from "../../common/PageComponents";
import { useQueryDocumentType, useSaveDocumentType } from "../../../queries/document-types/detail";
import { useCmsContext } from "../../../context/app/CmsContext";
import useRules from "../../../hooks/useRules";
import { useForm } from "react-hook-form";
import ControlledInput from "../../form/ControlledInput";
import ControlledMultilingualText from "../../form/ControlledMultilingualText";

export const DocumentTypeDetails = () => {
  const { t } = useTranslation();
  const { canUpdate } = useAuthorization("documentType");
  const { push } = useHistory();
  const { id: documentTypeId } = useParams<Entity>();
  const context = useCmsContext();
  const { maxLength, required } = useRules();

  const {
    data: documentType,
    isLoading: isLoadingDocumentType,
    refetch: refetchDocumentType,
  } = useQueryDocumentType(documentTypeId);
  const { mutateAsync: saveDocumentType, isLoading: isLoadingSaveDocumentType } =
    useSaveDocumentType();

  React.useEffect(() => {
    if (documentType) {
      context?.setDuplicateErrorMessage(
        t("errors:duplicateDocumentType", {
          isoCode: documentType.code,
        }),
      );

      context?.setBreadcrumbItems([
        {
          key: "document-type",
          name: documentType.name ?? t("common:new"),
        },
      ]);
    }
  }, [documentType, t]);

  const {
    handleSubmit,
    control,
    formState: { isValid, isDirty, isSubmitting },
  } = useForm<DocumentType>({
    mode: "onChange",
    values: documentType,
  });

  const isLoadingAny = (isLoadingDocumentType && !!documentTypeId) || isLoadingSaveDocumentType;
  const noDataAny = !documentType && !!documentTypeId;

  const submit = async (documentType: DocumentType) => {
    await saveDocumentType(documentType);
    !documentType.id ? push(`/document-types`) : await refetchDocumentType();
  };

  if (isLoadingAny || noDataAny) {
    return (
      <CmsPageLoader
        loading={true}
        key={"keycloak-pageloader"}
        title={t("common:loadingData")}
        subTitle={t("common:pleaseHold")}
      />
    );
  }

  return (
    <CmsForm>
      <Prompt when={isDirty} message={t("common:unsavedChanges")} />
      <CmsPageHeader
        title={t("entities:documentType")}
        extra={[
          <CmsBackButton
            key="back"
            disabled={isLoadingAny}
            onClick={() => push(`/document-types`)}
          />,
          !!canUpdate && (
            <CmsSaveButton
              key="save"
              disabled={!isValid || isLoadingAny}
              loading={isSubmitting}
              onClick={handleSubmit(submit)}
            />
          ),
        ]}
      />

      <ControlledInput
        control={control}
        name={"name"}
        isLoading={isLoadingAny}
        canUpdate={canUpdate}
        label={t("properties:name")}
        rules={{
          ...maxLength(100, t("properties:name")),
          ...required(t("properties:name")),
        }}
      />
      <ControlledMultilingualText
        control={control}
        name={"caption"}
        isLoading={isLoadingAny}
        canUpdate={canUpdate}
        label={t("properties:caption")}
      />
      <ControlledInput
        control={control}
        name={"code"}
        isLoading={isLoadingAny}
        canUpdate={canUpdate}
        label={t("properties:code")}
        rules={{
          ...maxLength(2, t("properties:code")),
          ...required(t("properties:code")),
        }}
      />
    </CmsForm>
  );
};
