import { Button, Input, Tabs } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { MultilingualInputData } from "../../models/MultilingualInputData";
import { MultilingualCompareList } from "./MultilingualCompareList";
import styles from "./MultilingualInput.module.scss";
import { CmsTabs } from "./PageComponents";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import { Editor } from "@tinymce/tinymce-react";
import { Editor as TinyMCEEditor } from "tinymce";
import { useFormikContext } from "formik";
import { DocumentSeries } from "../../models/data/Document";
import { useCmsContext } from "../../context/app/CmsContext";
import { useQueryOrderedLanguages } from "../../queries/languages/lists";
import { Noop, RefCallBack } from "react-hook-form";

const { TabPane } = Tabs;

export const MultilingualInput = (props: {
  name?: string;
  mlData: MultilingualInputData;
  readOnly?: boolean;
  disabled?: boolean;
  multiLine?: boolean;
  hideTabs?: boolean;
  onChange?: (model: MultilingualInputData) => void;
  onBlur?: Noop;
  ref?: RefCallBack;
  compareList?: boolean;
  richEditor?: boolean;
  autoSave?: boolean;
}) => {
  const context = useCmsContext();
  const tabPanePrefix = "multilingual_tabpane_";
  const { t } = useTranslation();
  const { data: languages } = useQueryOrderedLanguages();
  const formikContext = useFormikContext<DocumentSeries>();
  const editorRef = React.useRef<TinyMCEEditor | null>(null);

  useEffect(() => {
    const getView = localStorage.getItem("multilingualCompareListView");
    if (getView != null) {
      context?.setMultiLangualCompareView(JSON.parse(getView) === true);
    }
  }, []);

  const update = (key: string, value: string) =>
    props.onChange && props.onChange({ ...props.mlData, [key]: value });

  const inputProperties = (isoCode: string) => ({
    disabled: props.disabled,
    readOnly: props.readOnly,
    minLength: 0,
    onChange: (e) => update(isoCode, e.target.value),
    value: props.mlData && props.mlData[isoCode?.toLowerCase()],
    placeholder: t("properties:translation"),
  });

  return (
    <>
      <div className={styles["multilingualInput"]}>
        <CmsTabs
          activeKey={`${tabPanePrefix}${context?.multiLangualInputActive}`}
          onChange={(key) => {
            if (props.autoSave && formikContext?.dirty) formikContext.submitForm();
            context?.setMultiLangualInputActive(key.substring(tabPanePrefix.length));
          }}
          tabBarExtraContent={
            <Button
              type="default"
              key="new"
              icon={!context?.multiLangualCompareView ? <EyeOutlined /> : <EyeInvisibleOutlined />}
              size="middle"
              title={t(
                `texts:${
                  !context?.multiLangualCompareView ? "showCompareLanguage" : "hideCompareLanguage"
                }`,
              )}
              onClick={() => {
                context?.setMultiLangualCompareView(!context?.multiLangualCompareView);
                localStorage.setItem(
                  "multilingualCompareListView",
                  (!context?.multiLangualCompareView).toString(),
                );
              }}
            />
          }
          tabBarStyle={{ marginBottom: 0, borderBottom: 0, height: "30px" }}
          className={props.hideTabs ? styles["hidden-tabs"] : undefined}
        >
          {languages?.map((language) => (
            <TabPane
              key={`${tabPanePrefix}${language.isoCode.toLowerCase()}`}
              tab={language.isoCode.toUpperCase()}
            >
              {!props.multiLine ? (
                <>
                  <Input
                    key={`multilingual_input_${language.isoCode.toLowerCase()}`}
                    {...inputProperties(language.isoCode.toLowerCase())}
                    name={props.name}
                    ref={props.ref}
                    onBlur={props.onBlur}
                  />
                  {!!languages && context?.multiLangualCompareView && (
                    <MultilingualCompareList
                      mlData={props.mlData}
                      languages={languages}
                      translation={t}
                    />
                  )}
                </>
              ) : (
                <>
                  {props.richEditor ? (
                    <Editor
                      key={`multilingual_input_textarea_${language.isoCode.toLowerCase()}`}
                      onInit={(_, editor) => (editorRef.current = editor)}
                      init={{
                        height: 300,
                        width: "100%",
                        menubar: false,
                        paste_preprocess(_, args) {
                          args.content = args.content.replace(/font-family:(.*?)/g, "");
                        },
                        toolbar:
                          "bold italic underline strikethrough styleselect | numlist bullist | cut copy paste pasteword | search replace | undo redo | cleanup code | print | charmap removeformat visualchars | ",
                      }}
                      onEditorChange={(newValue) =>
                        update(language.isoCode.toLowerCase(), newValue)
                      }
                      disabled={props.disabled}
                      value={props.mlData && props.mlData[language.isoCode?.toLowerCase()]}
                    />
                  ) : (
                    <Input.TextArea
                      key={`multilingual_input_textarea_${language.isoCode.toLowerCase()}`}
                      rows={5}
                      {...inputProperties(language.isoCode.toLowerCase())}
                      name={props.name}
                      ref={props.ref}
                      onBlur={props.onBlur}
                    />
                  )}
                  {!!languages && context?.multiLangualCompareView && (
                    <MultilingualCompareList
                      mlData={props.mlData}
                      languages={languages}
                      translation={t}
                    />
                  )}
                </>
              )}
            </TabPane>
          ))}
        </CmsTabs>
      </div>
    </>
  );
};
