import React from "react";
import { useTranslation } from "react-i18next";
import { Prompt, useHistory, useParams } from "react-router-dom";
import { useAuthorization } from "../../../hooks/useAuthorization";
import { Entity } from "../../../models/data/Entity";
import { MaterialType } from "../../../models/reference_lists/MaterialType";
import { CmsBackButton, CmsSaveButton } from "../../common/ButtonComponents";
import { CmsForm } from "../../common/FormComponents";
import { CmsPageHeader, CmsPageLoader } from "../../common/PageComponents";
import { useQueryMaterialType, useSaveMaterialType } from "../../../queries/material-types/detail";
import { useCmsContext } from "../../../context/app/CmsContext";
import useRules from "../../../hooks/useRules";
import { useForm } from "react-hook-form";
import ControlledInput from "../../form/ControlledInput";
import ControlledMultilingualText from "../../form/ControlledMultilingualText";

export const MaterialTypeDetails = () => {
  const { t } = useTranslation();
  const { canUpdate } = useAuthorization("materialType");
  const { push } = useHistory();
  const { id: materialTypeId } = useParams<Entity>();
  const context = useCmsContext();
  const { maxLength, required } = useRules();

  const {
    data: materialType,
    isLoading: isLoadingMaterialType,
    refetch: refetchMaterialType,
  } = useQueryMaterialType(materialTypeId);
  const { mutateAsync: saveMaterialType, isLoading: isSavingMaterialType } = useSaveMaterialType();

  React.useEffect(() => {
    if (materialType) {
      context?.setDuplicateErrorMessage(
        t("errors:duplicateMaterialType", {
          name: materialType.name,
        }),
      );

      context?.setBreadcrumbItems([
        {
          key: "material-type",
          name: materialType.name ?? t("common:new"),
        },
      ]);
    }
  }, [materialType, t]);

  const {
    handleSubmit,
    control,
    formState: { isValid, isDirty, isSubmitting },
  } = useForm<MaterialType>({
    mode: "onChange",
    values: materialType,
  });

  const isLoadingAny = (isLoadingMaterialType && !!materialTypeId) || isSavingMaterialType;
  const noDataAny = !materialType && !!materialTypeId;

  const submit = async (materialType: MaterialType) => {
    await saveMaterialType(materialType);
    !materialType.id ? push(`/material-types`) : await refetchMaterialType();
  };

  if (isLoadingAny || noDataAny) {
    return (
      <CmsPageLoader
        loading={true}
        key={"keycloak-pageloader"}
        title={t("common:loadingData")}
        subTitle={t("common:pleaseHold")}
      />
    );
  }

  return (
    <CmsForm>
      <Prompt when={isDirty} message={t("common:unsavedChanges")} />
      <CmsPageHeader
        title={t("entities:materialType")}
        extra={[
          <CmsBackButton
            key="back"
            disabled={isLoadingAny}
            onClick={() => push(`/material-types`)}
          />,
          !!canUpdate && (
            <CmsSaveButton
              key="save"
              disabled={!isValid || isLoadingAny}
              loading={isSubmitting}
              onClick={handleSubmit(submit)}
            />
          ),
        ]}
      />

      <ControlledInput
        control={control}
        name={"name"}
        isLoading={isLoadingAny}
        canUpdate={canUpdate}
        label={t("properties:name")}
        rules={{
          ...maxLength(100, t("properties:name")),
          ...required(t("properties:name")),
        }}
      />

      <ControlledMultilingualText
        control={control}
        name={"caption"}
        isLoading={isLoadingAny}
        canUpdate={canUpdate}
        label={t("properties:caption")}
      />
    </CmsForm>
  );
};
