import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useAuthorization } from "../../../hooks/useAuthorization";
import { MaterialType } from "../../../models/reference_lists/MaterialType";
import { CmsCreateButton } from "../../common/ButtonComponents";
import { CmsPageHeader, CmsSearch, CmsTable } from "../../common/PageComponents";
import { useQueryMaterialTypes } from "../../../queries/material-types/lists";
import { useDeleteMaterialType } from "../../../queries/material-types/detail";
import { useCmsContext } from "../../../context/app/CmsContext";

export const MaterialTypeList = () => {
  const { t } = useTranslation();
  const { canCreate, canDelete, canUpdate, canView } = useAuthorization("materialType");
  const { push } = useHistory();
  const context = useCmsContext();
  const {
    data: materialTypes,
    isLoading: isLoadingMaterialTypes,
    refetch: refetchMaterialTypes,
  } = useQueryMaterialTypes();
  const { mutateAsync: deleteMaterialType, isLoading: isDeletingMaterialType } =
    useDeleteMaterialType();

  const [materialTypesFilter, setMaterialTypesFilter] = useState<MaterialType[]>(
    materialTypes ?? [],
  );

  useEffect(() => {
    if (!!materialTypes) {
      setMaterialTypesFilter(materialTypes);
    }
  }, [materialTypes]);

  useEffect(() => {
    if (materialTypesFilter) {
      context?.setDuplicateErrorMessage(t("errors:materialTypeLinked"));
    }
  }, [materialTypesFilter, t]);

  const search = (searchText: any) => {
    if (materialTypes) {
      const search = searchText.toLowerCase();
      const filteredMaterialTypes = materialTypes.filter((materialType) => {
        const name = materialType.name && materialType.name.toLowerCase();
        return name && name.includes(search);
      });

      setMaterialTypesFilter(filteredMaterialTypes);
    }
  };

  return (
    <React.Fragment>
      <CmsPageHeader
        title={t("entities:materialTypes")}
        extra={[
          <CmsSearch key="search" onChange={search} />,
          canCreate ? (
            <CmsCreateButton key="create" onClick={() => push(`/material-type`)} />
          ) : null,
        ]}
      />
      <CmsTable
        loading={isLoadingMaterialTypes || isDeletingMaterialType}
        dataSource={materialTypesFilter}
        rowKey="id"
        columns={[{ title: t("properties:name"), width: "30%", dataIndex: "name" }]}
        onEdit={canUpdate || canView ? (id) => push(`/material-type/${id}`) : undefined}
        onDelete={
          canDelete
            ? async (id) => {
                await deleteMaterialType(id);
                await refetchMaterialTypes();
              }
            : undefined
        }
      />
    </React.Fragment>
  );
};
