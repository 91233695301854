import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Country } from "../../models/reference_lists/Country";
import api from "../../services/api";
import { useTranslation } from "react-i18next";
import { useNotification } from "../../hooks/useNotification";
import { useMutationBase } from "../useMutationBase";
import { CONST_COUNTRIES } from "../../utilities/constants/query-contants";

export const getCountry = (id?: string): Promise<Country> => {
  return api.get({
    path: `${CONST_COUNTRIES.plural}/${id}`,
  });
};

export const useQueryCountry = (id?: string) => {
  return useQuery<Country>([CONST_COUNTRIES.singular, { id }], () => getCountry(id), {
    enabled: !!id,
  });
};

export const postCountry = (payload: Country): Promise<void> => {
  return api.post({ path: CONST_COUNTRIES.plural, body: { ...payload } });
};

export const useSaveCountry = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { notifySuccess } = useNotification();

  return useMutationBase({
    mutationFn: (payload: Country) => postCountry(payload),
    onSuccess: () => {
      queryClient.invalidateQueries([CONST_COUNTRIES.singular]);
      queryClient.invalidateQueries([CONST_COUNTRIES.plural]);

      notifySuccess(
        t("common:successSave", {
          entity: t("entities:country").toLocaleLowerCase(),
        }),
      );
    },
  });
};

export const deleteCountry = (id: string) => {
  return api.delete({ path: `${CONST_COUNTRIES.plural}/${id}` });
};

export const useDeleteCountry = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { notifySuccess } = useNotification();

  return useMutationBase({
    mutationFn: (id: string) => deleteCountry(id),
    onSuccess: () => {
      queryClient.invalidateQueries([CONST_COUNTRIES.singular]);
      queryClient.invalidateQueries([CONST_COUNTRIES.plural]);

      notifySuccess(
        t("common:successDelete", {
          entity: t("common:country").toLocaleLowerCase(),
        }),
      );
    },
  });
};
