import { Button, Checkbox, Form, Input, Layout, Modal } from "antd";
import { Formik } from "formik";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Product } from "../../../models/reference_lists/Product";
import { validateName, validateRequired } from "../../../utilities/Validators";
import { CmsModalForm } from "../../common/FormComponents";

interface Props {
  visible: boolean;
  product: Product;
  onClose(): void;
  onSave(product: Product): void;
}

export const ProductDetailsModal = (props: Props) => {
  const { t } = useTranslation();

  const formLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 18 },
    },
  };

  const validate = (product: Product) => {
    const errors: any = {};

    const nameResult =
      validateRequired(product.name, t("properties:name")) ||
      validateName(product.name, t("properties:name"));
    if (nameResult != null) {
      errors.name = nameResult;
    }

    return errors;
  };

  if (props.product == null) {
    return null;
  } else {
    return (
      <Formik
        initialValues={props.product}
        enableReinitialize={true}
        validate={validate}
        onSubmit={(values) => {
          props.onSave(values);
        }}
      >
        {(formikProps) => {
          const { handleChange, handleSubmit, setFieldValue, values, errors } = formikProps;

          return (
            <Modal
              width={700}
              title={t("entities:product")}
              open={props.visible}
              footer={[
                <Button id="btnCancel" key="cancel" onClick={props.onClose}>
                  {t("common:cancel")}
                </Button>,
                <Button
                  id="btnSave"
                  key="save"
                  type="primary"
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  {t("common:save")}
                </Button>,
              ]}
              onCancel={props.onClose}
            >
              <Layout style={{ background: "#fff" }}>
                <div key="productDetails">
                  <CmsModalForm>
                    <Form.Item
                      {...formLayout}
                      label={t("properties:name")}
                      validateStatus={!errors.name ? "success" : "error"}
                      help={errors.name}
                      required={true}
                    >
                      <Input
                        id="product-name"
                        key="2"
                        type="text"
                        placeholder={t("properties:name")}
                        maxLength={150}
                        value={values.name}
                        onChange={(e) => setFieldValue("name", e.target.value)}
                        onBlur={handleChange}
                      />
                    </Form.Item>

                    <Form.Item {...formLayout} label={t("properties:trademark")}>
                      <Checkbox
                        name="hasTrademark"
                        checked={values.hasTrademark}
                        onChange={(e) => setFieldValue("hasTrademark", e.target.checked)}
                      />
                    </Form.Item>
                    <Form.Item {...formLayout} label={t("properties:registered")}>
                      <Checkbox
                        name="isRegistered"
                        checked={values.isRegistered}
                        onChange={(e) => setFieldValue("isRegistered", e.target.checked)}
                      />
                    </Form.Item>
                  </CmsModalForm>
                </div>
              </Layout>
            </Modal>
          );
        }}
      </Formik>
    );
  }
};
