import { TFunction } from "i18next";
import { Note } from "../../models/data/Note";
import { validateRequired } from "../Validators";

export const validateNote = (note: Note, t: TFunction) => {
  const errors: any = {};

  const titleResult = validateRequired(note.title, t("properties:title"));
  if (titleResult != null) {
    errors.title = titleResult;
  }

  const descriptionResult = validateRequired(note.description, t("properties:description"));
  if (descriptionResult != null) {
    errors.description = descriptionResult;
  }

  return errors;
};
