import { useMutation, useQuery } from "@tanstack/react-query";
import api from "../../services/api";
import { CONST_BULK_IMAGE_UPLOADS } from "../../utilities/constants/query-contants";
import { BulkImageUpload } from "../../models/data/BulkImageUpload";

export const getbulkImageUploads = <T>(extension?: string): Promise<T> => {
  return api.get({
    path: `${CONST_BULK_IMAGE_UPLOADS.plural}${!!extension ? `/${extension}` : ""}`,
  });
};

export const useQuerybulkImageUploads = () => {
  return useQuery<BulkImageUpload[]>([CONST_BULK_IMAGE_UPLOADS.plural], () =>
    getbulkImageUploads<BulkImageUpload[]>(),
  );
};

export const useProcessBulkImages = () => useMutation(() => getbulkImageUploads("process"));

export const useQuerybulkImageUploadsStatus = () => {
  return useQuery<boolean>([`${CONST_BULK_IMAGE_UPLOADS.plural}/status`], () =>
    getbulkImageUploads<boolean>("status"),
  );
};
