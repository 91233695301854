import { MultilingualInputData } from "./MultilingualInputData";
import { AttributeDataType } from "./templates/Attribute";

export interface ISchema {
  errorMessage: string;
}

export interface ITextSchema extends ISchema {
  maxLength: number;
  minLength: number;
  regex: string;
}

export interface IMultiLingualTextSchema extends ISchema {
  maxLength: number;
  minLength: number;
  regex: string;
}

export interface INumberSchema extends ISchema {
  decimalCount: number;
  maxValue: number;
  minValue: number;
}

export interface IDateTimeSchema extends ISchema {
  format: DateTimeFormat;
}

export interface IListSchema extends ISchema {
  multiSelect: boolean;
  items: IListItem[];
}

export interface IObjectSchema extends ISchema {
  items: IObjectItem[];
  maxLength: number;
  regex: string;
}

export interface IObjectItem {
  key: string;
  value: string;
}

export interface IListItem {
  code: string;
  name: MultilingualInputData;
}

export type AttributeSchema =
  | INumberSchema
  | ITextSchema
  | IMultiLingualTextSchema
  | IDateTimeSchema
  | IListSchema
  | IObjectSchema
  | null;

export enum DateTimeFormat {
  DateTime = "DATE_TIME",
  Date = "DATE",
  Time = "TIME",
  YearAndMonth = "YEAR_AND_MONTH",
  Year = "YEAR",
}

export function getDefaultSchema(datatype: AttributeDataType): AttributeSchema {
  switch (datatype) {
    case AttributeDataType.Text:
      return {
        minLength: 0,
        maxLength: 100,
        regex: ".*",
        errorMessage: "The input is invalid and doesn't meet text input formating.",
      };
    case AttributeDataType.MultiLingualText:
      return {
        minLength: 0,
        maxLength: 100,
        regex: ".*",
        errorMessage: "The input is invalid and doesn't meet text input formating.",
      };
    case AttributeDataType.Number:
      return {
        minValue: 0,
        maxValue: 100,
        decimalCount: 0,
        errorMessage: "Provided value doesn't meet the number format requirement.",
      };
    case AttributeDataType.DateTime:
      return {
        format: DateTimeFormat.YearAndMonth,
        errorMessage: "Provided value does not meet the requirements.",
      };
    case AttributeDataType.List:
      return {
        multiSelect: false,
        items: [{ code: "", name: {} }],
        errorMessage: "Provided values do not meet the requirements.",
      };
    case AttributeDataType.Object:
      return {
        maxLength: 100,
        regex: ".*",
        items: [],
        errorMessage: "Provided values do not meet the requirements.",
      };
    default:
      return null;
  }
}
