import { useQuery } from "@tanstack/react-query";
import { Product } from "../../models/reference_lists/Product";
import api from "../../services/api";
import { CONST_PRODUCTS } from "../../utilities/constants/query-contants";

export const getProducts = (): Promise<Product[]> => {
  return api.get({
    path: CONST_PRODUCTS.plural,
  });
};

export const useQueryProducts = () => {
  return useQuery<Product[]>([CONST_PRODUCTS.plural], () => getProducts());
};
