import { useQuery, useQueryClient } from "@tanstack/react-query";
import { DocumentTemplate } from "../../models/templates/DocumentTemplate";
import api from "../../services/api";
import { CONST_DOCUMENT_TEMPLATES } from "../../utilities/constants/query-contants";
import { useTranslation } from "react-i18next";
import { useNotification } from "../../hooks/useNotification";
import { useMutationBase } from "../useMutationBase";

export const getDocumentTemplate = (id?: string): Promise<DocumentTemplate> => {
  return api.get({
    path: `${CONST_DOCUMENT_TEMPLATES.plural}/${id}`,
  });
};

export const useQueryDocumentTemplate = (id?: string) => {
  return useQuery<DocumentTemplate>(
    [CONST_DOCUMENT_TEMPLATES.singular, { id }],
    () => getDocumentTemplate(id),
    { enabled: !!id },
  );
};

export const postDocumentTemplate = (payload: DocumentTemplate): Promise<void> => {
  return api.post({
    path: CONST_DOCUMENT_TEMPLATES.plural,
    body: { ...payload },
  });
};

export const useSaveDocumentTemplate = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { notifySuccess } = useNotification();

  return useMutationBase({
    mutationFn: (payload: DocumentTemplate) => postDocumentTemplate(payload),
    onSuccess: () => {
      queryClient.invalidateQueries([CONST_DOCUMENT_TEMPLATES.singular]);
      queryClient.invalidateQueries([CONST_DOCUMENT_TEMPLATES.plural]);

      notifySuccess(
        t("common:successSave", {
          entity: t("entities:country").toLocaleLowerCase(),
        }),
      );
    },
  });
};

export const deleteDocumentTemplate = (id: string) => {
  return api.delete({ path: `${CONST_DOCUMENT_TEMPLATES.plural}/${id}` });
};

export const useDeleteDocumentTemplate = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { notifySuccess } = useNotification();

  return useMutationBase({
    mutationFn: (id: string) => deleteDocumentTemplate(id),
    onSuccess: () => {
      queryClient.invalidateQueries([CONST_DOCUMENT_TEMPLATES.singular]);
      queryClient.invalidateQueries([CONST_DOCUMENT_TEMPLATES.plural]);

      notifySuccess(
        t("common:successDelete", {
          entity: t("common:country").toLocaleLowerCase(),
        }),
      );
    },
  });
};
