import { TFunction } from "i18next";
import { Field } from "../../models/templates/Field";
import { validateName, validateRegEx, validateRequired } from "../Validators";
import { FieldValue } from "../../models/data/FieldValue";
import { Crop } from "react-image-crop";
import { Point } from "react-lasso-select/lib/helpers";

export const ValidateField = (field: Field, t: TFunction) => {
  const errors: any = {};

  const groupIdResult = validateRequired(field.groupId, t("properties:group"));
  if (groupIdResult != null) {
    errors.groupId = groupIdResult;
  }

  const nameResult =
    validateRequired(field.name, t("properties:name")) ||
    validateName(field.name, t("properties:name"));
  if (nameResult != null) {
    errors.name = nameResult;
  }

  const codeResult =
    validateRequired(field.code, t("properties:code")) ||
    validateRegEx(
      field.code,
      /^[A-Z0-9_]*$/,
      t("validations:code", { property: t("properties:code") }),
    );
  if (codeResult != null) {
    errors.code = codeResult;
  }

  return errors;
};

export const constructFieldValues = (
  fieldValues: FieldValue[],
  fieldLinkId: string,
  imageId: string,
) =>
  fieldValues.map((field) => {
    const compiledField = {
      ...field,
      fieldLinkId: fieldLinkId,
      imageId: imageId,
    } as FieldValue;

    switch (field.shapeType) {
      case "RECTANGLE":
        const rectangle = {
          height: field.height,
          width: field.width,
          x: field.x,
          y: field.y,
        } as Crop;

        compiledField.height = rectangle.height;
        compiledField.width = rectangle.width;
        compiledField.y = rectangle.y;
        compiledField.x = rectangle.x;
        break;
      case "POLYGON":
        const polygon = field.points as Point[];
        compiledField.height = undefined;
        compiledField.width = undefined;
        compiledField.y = undefined;
        compiledField.x = undefined;
        compiledField.points = polygon;
        break;
      default:
        break;
    }
    return compiledField;
  }) as FieldValue[];
