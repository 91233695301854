import {} from "@ant-design/icons";
import { Button, Form, Input } from "antd";
import { Group } from "../../models/templates/Group";
import Modal from "antd/lib/modal/Modal";
import { validateRequired, validateName } from "../../utilities/Validators";
import { useTranslation } from "react-i18next";
import { CmsModalForm } from "./FormComponents";
import { Formik } from "formik";

export const GroupManagerDetail = (props: {
  visible: boolean;
  title: string;
  group: Group | null;
  onClose(): void;
  onSave(group: Group): void;
}) => {
  const { t } = useTranslation();

  const validate = (group: Group) => {
    const errors: any = {};

    const nameResult =
      validateRequired(group.name, t("properties:name")) ||
      validateName(group.name, t("properties:name"));
    if (nameResult != null) {
      errors.name = nameResult;
    }

    return errors;
  };

  return (
    <Formik
      initialValues={props.group ?? {}}
      enableReinitialize={true}
      validate={validate}
      onSubmit={(group) => {
        props.onSave(group);
      }}
    >
      {(formikProps) => {
        const { values, handleSubmit, setFieldValue, errors, isValid } = formikProps;

        return (
          <Modal
            width={400}
            title={props.title}
            open={props.visible}
            onCancel={props.onClose}
            footer={[
              <Button
                id="btnCancel"
                key="cancel"
                style={{ marginRight: "8px" }}
                onClick={props.onClose}
              >
                {t("common:cancel")}
              </Button>,
              <Button
                id="btnSave"
                key="save"
                type="primary"
                disabled={!isValid}
                onClick={() => handleSubmit()}
              >
                {t("common:save")}
              </Button>,
            ]}
          >
            <CmsModalForm>
              <Form.Item
                label={t("properties:name")}
                validateStatus={!errors.name ? "success" : "error"}
                help={errors.name}
                required={true}
              >
                <Input
                  id="name"
                  type="text"
                  placeholder={t("properties:name")}
                  style={{ width: "250px" }}
                  maxLength={30}
                  value={values.name}
                  onChange={(e) => setFieldValue("name", e.target.value)}
                />
              </Form.Item>
            </CmsModalForm>
          </Modal>
        );
      }}
    </Formik>
  );
};
