import { PrinterOutlined } from "@ant-design/icons";
import { Button } from "antd";
import Modal from "antd/lib/modal/Modal";
import { Printd } from "printd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { View } from "../../../models/data/View";
import { ViewTemplate } from "../../../models/templates/ViewTemplate";
import { CmsCancelButton, CmsDownloadButton } from "../../common/ButtonComponents";
import { CmsPageLoader } from "../../common/PageComponents";
import "./ScanOrder.scss";
import { useExporScanOrder, useQueryScanOrder } from "../../../queries/document-series/detail";
import { ScanOrderRow } from "../../../queries/document-series/document-series-type";
import { selectIndexByLetter } from "../../../utilities/AlfabetHelper";

export const ScanOrder = (props: {
  documentCode: string;
  documentId?: string;
  views: View[];
  viewTemplates: ViewTemplate[];
  onClose: () => void;
}) => {
  const { t } = useTranslation();
  const printComponent = new Printd();
  const printCss = ["/assets/css/scanorderprint.css"];
  const { mutateAsync: downloadScanOrder, isLoading: isDownloadingScanOrder } = useExporScanOrder(
    props.documentCode,
  );
  const { data: scanOrder, isLoading: isLoadingScanOrder } = useQueryScanOrder(props.documentId);
  const [rows, setRows] = useState<{
    attributes: ScanOrderRow[];
    securityFeatures: ScanOrderRow[];
  }>();

  useEffect(() => {
    if (scanOrder) {
      setRows({
        attributes: scanOrder
          .flatMap((item) => item.mainImages)
          .sort((a, b) => (a.page > b.page ? -1 : 1)),
        securityFeatures: scanOrder
          .flatMap((item) => item.subImages)
          .sort((a, b) => (a.page > b.page ? -1 : 1)),
      });
    }
  }, [scanOrder]);

  const renderRow = (
    rowKey: string,
    page: string,
    image: string,
    imagesCount: number,
    imageIndex: number,
    imageCode: string,
    remarks?: string,
  ) => {
    return (
      <tr key={`row_${rowKey}`}>
        <td>{page}</td>
        {imagesCount > 1 ? (
          imageIndex === 0 ? (
            <td rowSpan={imagesCount} className="multiRowColumn">
              {image}
            </td>
          ) : null
        ) : (
          <td>{image}</td>
        )}
        <td>&nbsp;</td>
        <td>{imageCode}</td>
        <td>&nbsp;</td>
        <td>{remarks}</td>
      </tr>
    );
  };

  return (
    <Modal
      width={"60%"}
      title={t("common:scanOrder")}
      destroyOnClose={true}
      open={true}
      onCancel={props.onClose}
      style={{ paddingBottom: 0, maxWidth: "80vw" }}
      bodyStyle={{ maxHeight: "80vh", maxWidth: "80vw", overflow: "auto" }}
      centered={true}
      footer={[
        <CmsCancelButton key="cancel" onClick={props.onClose}>
          {t("common:cancel")}
        </CmsCancelButton>,
        <Button
          key="btnPrint"
          type="primary"
          icon={<PrinterOutlined />}
          style={{}}
          onClick={() =>
            printComponent.print(document.getElementById("mainDiv") as HTMLElement, printCss)
          }
        >
          {t("common:print")}
        </Button>,
        <CmsDownloadButton
          key="download"
          disabled={isDownloadingScanOrder}
          onClick={async () => {
            if (props.documentId) {
              await downloadScanOrder(props.documentId);
            }
          }}
        />,
      ]}
    >
      <div id="mainDiv">
        <h3>{props.documentCode}</h3>
        <div className="notes">{t("entities:notes")}</div>
        <br />
        <h4>{t("entities:images")}</h4>
        <CmsPageLoader loading={isLoadingScanOrder} subTitle={t("common:loadingData")}>
          {!!scanOrder && rows && (
            <table className="scanOrder">
              <tbody>
                <tr>
                  <th>{t("common:page")}</th>
                  <th>{t("common:image")}</th>
                  <th>{t("common:from")}</th>
                  <th>{t("common:imageCode")}</th>
                  <th>{t("common:done")}</th>
                  <th>{t("common:remarks")}</th>
                </tr>
                {rows.attributes.map((item) =>
                  renderRow(item.value, item.page, item.name, 1, 0, item.value),
                )}
                <tr>
                  <td colSpan={6} className="titleRow">
                    <h4>
                      {t("entities:securityFeature")} {t("entities:images").toLowerCase()}
                    </h4>
                  </td>
                </tr>
                <tr>
                  <th>{t("common:page")}</th>
                  <th>{t("common:image")}</th>
                  <th>{t("common:from")}</th>
                  <th>{t("common:imageCode")}</th>
                  <th>{t("common:done")}</th>
                  <th>{t("common:remarks")}</th>
                </tr>
                {rows.securityFeatures.map((item) => {
                  const multi = /(_[A-Z])/.test(item.value.slice(-2));
                  const index = multi ? selectIndexByLetter(item.value.slice(-1)) : 0;
                  const rowName = item.value.substring(0, item.value.length - 1);
                  const count = multi
                    ? rows.securityFeatures.filter(
                        (attr) => attr.value.substring(0, attr.value.length - 1) === rowName,
                      ).length
                    : 1;

                  return renderRow(item.value, item.page, item.name, count, index, item.value);
                })}
              </tbody>
            </table>
          )}
        </CmsPageLoader>
      </div>
    </Modal>
  );
};
