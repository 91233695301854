import { Crop } from "react-image-crop";
import ImageDimensions from "../models/data/TargetImageDimensions";

export const ConvertCrop = (
  width: number,
  height: number,
  crop: Crop,
  type: "ABSOLUTE" | "PERCENT",
  img: ImageDimensions,
): Crop | Partial<Crop> => {
  const cropWidth = crop.width as number;
  const cropHeight = crop.height as number;
  const cropX = crop.x as number;
  const cropY = crop.y as number;

  if (type === "ABSOLUTE") {
    return {
      width: img.width * cropWidth,
      height: img.height * cropHeight,
      y: img.height * cropY,
      x: img.width * cropX,
    };
  }

  if (type === "PERCENT") {
    const scaleX = img.natWidth / img.width;
    const scaleY = img.natHeight / img.height;

    const percentWidth = (width * scaleX) / 100;
    const percentHeight = (height * scaleY) / 100;
    return {
      height: cropHeight / percentHeight / 100,
      width: cropWidth / percentWidth / 100,
      y: cropY / percentHeight / 100,
      x: cropX / percentWidth / 100,
    };
  }
  return { unit: "px" };
};

export const CreatePercentCrop = (
  xRelative: number,
  yRelative: number,
  widthRelative: number,
  heightRelative: number,
): Crop => {
  if (xRelative > 1 || yRelative > 1 || widthRelative > 1 || heightRelative > 1) {
    xRelative = yRelative = widthRelative = heightRelative = 0;
  }

  return {
    unit: "%",
    x: xRelative * 100,
    y: yRelative * 100,
    width: widthRelative * 100,
    height: heightRelative * 100,
  };
};

export const CreateAbsoluteCrop = (
  imageWidth: number,
  imageHeight: number,
  xRelative: number,
  yRelative: number,
  widthRelative: number,
  heightRelative: number,
): Crop => {
  if (xRelative > 1 || yRelative > 1 || widthRelative > 1 || heightRelative > 1) {
    xRelative = yRelative = widthRelative = heightRelative = 0;
  }

  return {
    unit: "px",
    x: xRelative * imageWidth,
    y: yRelative * imageHeight,
    width: widthRelative * imageWidth,
    height: heightRelative * imageHeight,
  };
};

export const ConvertToPercentCrop = (imageWidth: number, imageHeight: number, crop: Crop): Crop => {
  return CreatePercentCrop(
    (crop.x ?? 0) / imageWidth,
    (crop.y ?? 0) / imageHeight,
    (crop.width ?? 0) / imageWidth,
    (crop.height ?? 0) / imageHeight,
  );
};

export const ConvertToRelativeCrop = (
  imageWidth: number,
  imageHeight: number,
  absoluteCrop: Crop,
) => {
  if (
    (absoluteCrop.x ?? 0) > imageWidth ||
    (absoluteCrop.y ?? 0) > imageHeight ||
    (absoluteCrop.width ?? 0) > imageWidth ||
    (absoluteCrop.height ?? 0) > imageHeight
  ) {
    absoluteCrop.x = absoluteCrop.y = absoluteCrop.height = absoluteCrop.width = 0;
  }

  return {
    unit: "%",
    x: absoluteCrop.x ?? 0 / imageWidth,
    y: absoluteCrop.y ?? 0 / imageHeight,
    width: absoluteCrop.width ?? 0 / imageWidth,
    height: absoluteCrop.height ?? 0 / imageHeight,
  };
};
