import i18n from "i18next";
import XRegExp from "xregexp";
import Config from "../Config";
import { MultilingualInputData } from "../models/MultilingualInputData";

// == Specific Validators ==
export const validateName = (value?: string, property?: string) => {
  if (value && value.length) {
    return validate(
      "",
      value,
      XRegExp("^[\\p{L}\\d\\s-?:().,'`+&\\\\\"/]*$"),
      i18n.t("validations:name", { property: property || "Input" }),
    );
  }

  return null;
};

export const validateEmail = (value?: string) => {
  if (value && value.length) {
    return validate(
      "",
      value,
      // tslint:disable-next-line:max-line-length
      /^((([a-z]|\d|[!#$%&'*+\-/=?^_`{|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#$%&'*+\-/=?^_`{|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/, // eslint-disable-line
      i18n.t("validations:email"),
    );
  }

  return null;
};

export const validateUrl = (value?: string) => {
  if (value && value.length) {
    return validate(
      "",
      value,
      // tslint:disable-next-line:max-line-length
      /^(https?|ftp):\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:|@)|\/|\?)*)?$/,
      i18n.t("validations:url"),
    );
  }

  return null;
};

export const validatePhoneNumber = (value?: string) => {
  if (value && value.length) {
    return validate("", value, /^\+[0-9]{8,13}$/, i18n.t("validations:phoneNumber"));
  }

  return null;
};

export const validateMLInput = (value: MultilingualInputData, property?: string) => {
  if (value && value[Config.defaultLanguage] && value[Config.defaultLanguage].length > 0) {
    return null;
  } else {
    return i18n.t("validations:inputDefaultLanguageRequired", {
      property: property || "Input",
    });
  }
};

export const validateDifferent = (
  value1?: string,
  value2?: string,
  name1?: string,
  name2?: string,
) => {
  return value1 === value2 ? i18n.t("validations:differentValues", { name1, name2 }) : null;
};

// == Generic Validators ==
export const validateMinChars = (value: string, min: number, property?: string) => {
  return (!value && min > 0) || value?.length < min
    ? i18n.t("validations:inputMinChars", {
        property: property || "Input",
        value: min,
      })
    : null;
};

export const validateMaxChars = (value?: string, max?: number, property?: string) => {
  return value && max && value?.length > max
    ? i18n.t("validations:inputMaxChars", {
        property: property || "Input",
        value: max,
      })
    : null;
};

export const validateRequired = (value?: string, property?: string) => {
  if (!value || value.length === 0) {
    return i18n.t("validations:inputRequired", {
      property: property || "Input",
    });
  }

  return null;
};

export const validateRegEx = (value?: string, regex?: RegExp, error?: string) => {
  return value && regex && error ? validate("", value, regex, error) : null;
};

// == Private Methods ==
function validate(fieldName: string, value: string, regex: RegExp, error: string) {
  return !XRegExp.test(value, regex) ? fieldName + " " + error : null;
}
