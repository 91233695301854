import { useMutation, useQuery } from "@tanstack/react-query";
import api from "../../services/api";
import { Hologram } from "./types";
import { CONST_HOLOGRAMS } from "../../utilities/constants/query-contants";
import { FilePayload, FileResponse } from "../files/file-types";
import { useNotification } from "../../hooks/useNotification";

export const getHologram = (id?: string): Promise<Hologram> => {
  return api.get({
    path: `${CONST_HOLOGRAMS.singular}/${id}`,
  });
};

export const useQueryHologram = (id?: string) => {
  return useQuery<Hologram>([CONST_HOLOGRAMS.singular, { id }], () => getHologram(id), {
    enabled: !!id,
  });
};

export const postHologram = (payload: FilePayload): Promise<FileResponse> => {
  const formData = new FormData();
  formData.append("file", payload.file);

  return api.post({
    path: CONST_HOLOGRAMS.singular,
    body: formData,
    isFile: true,
    isUpload: true,
  });
};

export const useUploadHologram = () => {
  const { notifySuccess } = useNotification();

  return useMutation((payload: FilePayload) => postHologram(payload), {
    onSuccess: () => {
      notifySuccess("The hologram import process was started succesfully.");
    },
  });
};
