import { useQuery, useQueryClient } from "@tanstack/react-query";
import api from "../../services/api";
import { useTranslation } from "react-i18next";
import { useNotification } from "../../hooks/useNotification";
import { useMutationBase } from "../useMutationBase";
import { DocumentType } from "../../models/reference_lists/DocumentType";
import { CONST_DOCUMENT_TYPES } from "../../utilities/constants/query-contants";

export const getDocumentType = (id?: string): Promise<DocumentType> => {
  return api.get({
    path: `${CONST_DOCUMENT_TYPES.plural}/${id}`,
  });
};

export const useQueryDocumentType = (id?: string) => {
  return useQuery<DocumentType>(
    [CONST_DOCUMENT_TYPES.singular, { id }],
    () => getDocumentType(id),
    {
      enabled: !!id,
    },
  );
};

export const postDocumentType = (payload: DocumentType): Promise<void> => {
  return api.post({ path: CONST_DOCUMENT_TYPES.plural, body: { ...payload } });
};

export const useSaveDocumentType = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { notifySuccess } = useNotification();

  return useMutationBase({
    mutationFn: (payload: DocumentType) => postDocumentType(payload),
    onSuccess: () => {
      queryClient.invalidateQueries([CONST_DOCUMENT_TYPES.singular]);
      queryClient.invalidateQueries([CONST_DOCUMENT_TYPES.plural]);

      notifySuccess(
        t("common:successSave", {
          entity: t("entities:documentType").toLocaleLowerCase(),
        }),
      );
    },
  });
};

export const deleteDocumentType = (id: string) => {
  return api.delete({ path: `${CONST_DOCUMENT_TYPES.plural}/${id}` });
};

export const useDeleteDocumentType = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { notifySuccess } = useNotification();

  return useMutationBase({
    mutationFn: (id: string) => deleteDocumentType(id),
    onSuccess: () => {
      queryClient.invalidateQueries([CONST_DOCUMENT_TYPES.singular]);
      queryClient.invalidateQueries([CONST_DOCUMENT_TYPES.plural]);

      notifySuccess(
        t("common:successDelete", {
          entity: t("common:documentType").toLocaleLowerCase(),
        }),
      );
    },
  });
};
