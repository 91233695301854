import { Button, Popconfirm, Upload } from "antd";
import { ButtonProps } from "antd/lib/button";
import React, { CSSProperties, FC, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import {
  LeftOutlined,
  RightOutlined,
  PlusOutlined,
  SaveOutlined,
  DeleteOutlined,
  UploadOutlined,
  DownloadOutlined,
  CloseOutlined,
  CopyOutlined,
  SendOutlined,
} from "@ant-design/icons";
import { UploadRequestOption as RcCustomRequestOptions } from "rc-upload/lib/interface";

// TODO: refactor all these buttons for dynamic ones

export type ButtonType =
  | "default"
  | "cancel"
  | "save"
  | "new"
  | "add"
  | "back"
  | "download"
  | "copy"
  | "send";
type Record<K extends string | number | symbol, T> = { [P in K]: T };
interface ButtonConfig extends ButtonProps {
  translationKey: string;
}
interface CmsButtonConfig extends ButtonProps {
  buttonType: ButtonType;
  confirmText?: string;
  children?: ReactNode;
  style?: CSSProperties;
  onClick?: (e?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

const buttonConfig = {
  default: {
    translationKey: "common:click",
  },
  cancel: {
    icon: <LeftOutlined />,
    translationKey: "common:cancel",
  },
  add: {
    icon: <PlusOutlined />,
    translationKey: "common:add",
    type: "primary",
  },
  back: { icon: <LeftOutlined />, translationKey: "common:back" },
  download: { icon: <DownloadOutlined />, translationKey: "common:download" },
  new: {
    icon: <LeftOutlined />,
    translationKey: "common:new",
    type: "primary",
  },
  save: {
    icon: <SaveOutlined />,
    translationKey: "common:save",
    type: "primary",
  },
  copy: {
    icon: <CopyOutlined />,
    translationKey: "common:copy",
    type: "primary",
  },
  send: {
    icon: <SendOutlined />,
    translationKey: "common:send",
    type: "primary",
  },
} as Record<ButtonType, ButtonConfig>;

export const CmsButton: FC<CmsButtonConfig> = (props: CmsButtonConfig) => {
  const { t } = useTranslation();

  if (props.confirmText) {
    return (
      <Popconfirm
        onConfirm={props.onClick}
        title={props.confirmText}
        onCancel={(e) => e?.stopPropagation()}
        okText={t("common:yes")}
        cancelText={t("common:no")}
      >
        <Button {...buttonConfig[props.buttonType]} {...props} onClick={(e) => e.stopPropagation()}>
          {props.children ?? t(buttonConfig[props.buttonType].translationKey)}
        </Button>
      </Popconfirm>
    );
  }

  return (
    <Button {...buttonConfig[props.buttonType]} {...props}>
      {props.children ?? t(buttonConfig[props.buttonType].translationKey)}
    </Button>
  );
};

export const CmsCancelButton: React.FunctionComponent<ButtonProps> = (props) => {
  const { t } = useTranslation();
  return (
    <Button {...props} icon={<LeftOutlined />}>
      {t("common:cancel")}
    </Button>
  );
};

export const CmsSaveButton: React.FunctionComponent<ButtonProps> = (props) => {
  const { t } = useTranslation();
  return (
    <Button {...props} icon={<SaveOutlined />} type="primary">
      {t("common:save")}
    </Button>
  );
};

export const CmsCreateButton: React.FunctionComponent<ButtonProps> = (props) => {
  const { t } = useTranslation();
  return (
    <Button {...props} icon={<PlusOutlined />} type="primary">
      {t("common:new")}
    </Button>
  );
};

export const CmsAddButton: React.FunctionComponent<ButtonProps> = (props) => {
  const { t } = useTranslation();
  return (
    <Button {...props} icon={<PlusOutlined />} type="primary">
      {t("common:add")}
    </Button>
  );
};

export const CmsBackButton: React.FunctionComponent<ButtonProps> = (props) => {
  const { t } = useTranslation();
  return (
    <Button {...props} icon={<LeftOutlined />}>
      {t("common:back")}
    </Button>
  );
};

export const CmsDownloadButton: React.FunctionComponent<ButtonProps> = (props) => {
  const { t } = useTranslation();
  return (
    <Button {...props} icon={<DownloadOutlined />}>
      {t("common:download")}
    </Button>
  );
};

export const CmsRemoveFiltersButton: React.FunctionComponent<ButtonProps> = (props) => {
  const { t } = useTranslation();
  return (
    <Button {...props} icon={<CloseOutlined />} danger>
      {t("common:removeFilter")}
    </Button>
  );
};

export const CmsRetractLeftButton: React.FunctionComponent<ButtonProps> = (props) => {
  const { t } = useTranslation();
  return (
    <Button {...props} icon={<LeftOutlined />}>
      {t("common:retractFilter")}
    </Button>
  );
};

export const CmsExpandRightButton: React.FunctionComponent<ButtonProps> = (props) => {
  const { t } = useTranslation();
  return (
    <Button {...props} icon={<RightOutlined />} type="primary">
      {t("common:expandFilter")}
    </Button>
  );
};

interface DeleteButtonProps {
  onConfirm: (e?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  style?: any;
}

export const CmsDeleteButton: React.FunctionComponent<DeleteButtonProps> = (props) => {
  const { t } = useTranslation();

  return (
    <Popconfirm
      onConfirm={props.onConfirm}
      title={t("common:confirmDelete")}
      onCancel={(e) => e?.stopPropagation()}
      okText={t("common:yes")}
      cancelText={t("common:no")}
    >
      <Button style={props.style} type="default" onClick={(e) => e.stopPropagation()}>
        <DeleteOutlined />
        {t("common:delete")}
      </Button>
    </Popconfirm>
  );
};

interface UploadButtonProps {
  customRequest: (options: RcCustomRequestOptions) => void;
  style?: any;
  accept?: string;
  disabled?: boolean;
  children?: ReactNode;
}

export const CmsUploadButton: React.FunctionComponent<UploadButtonProps> = (props) => {
  const { t } = useTranslation();

  return (
    <Upload
      customRequest={props.customRequest}
      showUploadList={false}
      accept={props.accept}
      disabled={props.disabled}
    >
      {props.children ?? (
        <Button style={props.style} type="primary" disabled={props.disabled}>
          <UploadOutlined />
          {t("common:upload")}
        </Button>
      )}
    </Upload>
  );
};
