import { useQueryClient } from "@tanstack/react-query";
import api from "../../services/api";
import { CONST_BULK_IMAGE_UPLOADS } from "../../utilities/constants/query-contants";
import { useTranslation } from "react-i18next";
import { useNotification } from "../../hooks/useNotification";
import { useMutationBase } from "../useMutationBase";
import { BulkImageDeletePayload, BulkImageUploadPayload } from "./types";
import { BulkImageUploadResults } from "../../models/data/BulkImageUploadResults";

export const postBulkImageUpload = (
  payload: BulkImageUploadPayload,
): Promise<BulkImageUploadResults[]> => {
  const formData = new FormData();
  for (let i = 0; i < payload.files.length; i++) {
    formData.append("files", payload.files[i]);
  }

  return api.post({
    path: `${CONST_BULK_IMAGE_UPLOADS.plural}?uploadCategory=${payload.category}`,
    body: formData,
    isFile: true,
    isUpload: true,
  });
};

export const useSaveBulkImageUpload = () => {
  const queryClient = useQueryClient();

  return useMutationBase({
    mutationFn: (payload: BulkImageUploadPayload) => postBulkImageUpload(payload),
    onSuccess: () => {
      queryClient.invalidateQueries([CONST_BULK_IMAGE_UPLOADS.singular]);
      queryClient.invalidateQueries([CONST_BULK_IMAGE_UPLOADS.plural]);
    },
  });
};

export const deleteBulkImageUpload = (payload: BulkImageDeletePayload) => {
  return api.delete({
    path: `${CONST_BULK_IMAGE_UPLOADS.plural}/${payload.category}/${payload.fileName}`,
  });
};

export const useDeleteBulkImageUpload = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { notifySuccess } = useNotification();

  return useMutationBase({
    mutationFn: (payload: BulkImageDeletePayload) => deleteBulkImageUpload(payload),
    onSuccess: () => {
      queryClient.invalidateQueries([CONST_BULK_IMAGE_UPLOADS.singular]);
      queryClient.invalidateQueries([CONST_BULK_IMAGE_UPLOADS.plural]);
      queryClient.invalidateQueries([`${CONST_BULK_IMAGE_UPLOADS.plural}/status`]);

      notifySuccess(
        t("common:successDelete", {
          entity: t("common:bulkImageUpload").toLocaleLowerCase(),
        }),
      );
    },
  });
};

export const deleteAllBulkImageUpload = () => {
  return api.delete({
    path: CONST_BULK_IMAGE_UPLOADS.plural,
  });
};

export const useDeleteAllBulkImageUpload = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { notifySuccess } = useNotification();

  return useMutationBase({
    mutationFn: () => deleteAllBulkImageUpload(),
    onSuccess: () => {
      queryClient.invalidateQueries([CONST_BULK_IMAGE_UPLOADS.singular]);
      queryClient.invalidateQueries([CONST_BULK_IMAGE_UPLOADS.plural]);
      queryClient.invalidateQueries([`${CONST_BULK_IMAGE_UPLOADS.plural}/status`]);

      notifySuccess(
        t("common:successDelete", {
          entity: t("common:bulkImageUpload").toLocaleLowerCase(),
        }),
      );
    },
  });
};
