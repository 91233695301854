import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Group } from "../../models/templates/Group";
import api from "../../services/api";
import { CONST_GROUPS } from "../../utilities/constants/query-contants";
import { useMutationBase } from "../useMutationBase";
import { useNotification } from "../../hooks/useNotification";
import { useTranslation } from "react-i18next";
import { GroupType } from "./group-types";

export const getGroup = (group: GroupType, id?: string): Promise<Group> => {
  return api.get({
    path: `${group}${CONST_GROUPS.plural}/${id}`,
  });
};

export const useQueryGroup = (group: GroupType, id?: string) => {
  return useQuery<Group>([CONST_GROUPS.singular, { group, id }], () => getGroup(group, id), {
    enabled: !!id,
  });
};

export const postGroup = (payload: Group, group: GroupType): Promise<void> => {
  return api.post({ path: `${group}${CONST_GROUPS.plural}`, body: { ...payload } });
};

export const useSaveGroup = (group: GroupType) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { notifySuccess } = useNotification();

  return useMutationBase({
    mutationFn: (payload: Group) => postGroup(payload, group),
    onSuccess: () => {
      queryClient.invalidateQueries([CONST_GROUPS.singular]);
      queryClient.invalidateQueries([CONST_GROUPS.plural]);

      notifySuccess(
        t("common:successSave", {
          entity: t("entities:group").toLocaleLowerCase(),
        }),
      );
    },
  });
};

export const deleteGroup = (group: GroupType, id: string) => {
  return api.delete({ path: `${group}${CONST_GROUPS.plural}/${id}` });
};

export const useDeleteGroup = (group: GroupType) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { notifySuccess } = useNotification();

  return useMutationBase({
    mutationFn: (id: string) => deleteGroup(group, id),
    onSuccess: () => {
      queryClient.invalidateQueries([CONST_GROUPS.singular]);
      queryClient.invalidateQueries([CONST_GROUPS.plural]);

      notifySuccess(
        t("common:successDelete", {
          entity: t("common:group").toLocaleLowerCase(),
        }),
      );
    },
  });
};
