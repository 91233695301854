import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Field } from "../../models/templates/Field";
import api from "../../services/api";
import { CONST_FIELDS } from "../../utilities/constants/query-contants";
import { useTranslation } from "react-i18next";
import { useNotification } from "../../hooks/useNotification";
import { useMutationBase } from "../useMutationBase";

export const getField = (id?: string): Promise<Field> => {
  return api.get({
    path: `${CONST_FIELDS.plural}/${id}`,
  });
};

export const useQueryField = (id?: string) => {
  return useQuery<Field>([CONST_FIELDS.singular, { id }], () => getField(id), {
    enabled: !!id,
  });
};

export const postField = (payload: Field): Promise<void> => {
  return api.post({ path: CONST_FIELDS.plural, body: { ...payload } });
};

export const useSaveField = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { notifySuccess } = useNotification();

  return useMutationBase({
    mutationFn: (payload: Field) => postField(payload),
    onSuccess: () => {
      queryClient.invalidateQueries([CONST_FIELDS.singular]);
      queryClient.invalidateQueries([CONST_FIELDS.plural]);

      notifySuccess(
        t("common:successSave", {
          entity: t("entities:field").toLocaleLowerCase(),
        }),
      );
    },
  });
};

export const deleteField = (id: string) => {
  return api.delete({ path: `${CONST_FIELDS.plural}/${id}` });
};

export const useDeleteField = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { notifySuccess } = useNotification();

  return useMutationBase({
    mutationFn: (id: string) => deleteField(id),
    onSuccess: () => {
      queryClient.invalidateQueries([CONST_FIELDS.singular]);
      queryClient.invalidateQueries([CONST_FIELDS.plural]);

      notifySuccess(
        t("common:successDelete", {
          entity: t("common:field").toLocaleLowerCase(),
        }),
      );
    },
  });
};
