import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Button, Card, Checkbox, Typography } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { DocumentSeries } from "../../../../models/data/Document";
import { DocumentTemplate } from "../../../../models/templates/DocumentTemplate";
import { getLocalizedValue } from "../../../../utilities/MultilingualHelper";
import { capitalizeFirstLetter } from "../../../../utilities/StringHelper";
import styles from "./DocumentWizard.module.scss";
import { SelectedViewAndImageTemplate } from "./interfaces";
const { Title } = Typography;

export const StepViewsAndImages = (props: {
  documentTemplate?: DocumentTemplate;
  document?: DocumentSeries;
  templates: SelectedViewAndImageTemplate[];
  onPrevious: () => void;
  onNext: (templates: SelectedViewAndImageTemplate[]) => void;
}) => {
  const { t } = useTranslation();

  const InitialTemplates = () => {
    if (props.templates.length > 0) {
      return props.templates;
    }

    const selections: SelectedViewAndImageTemplate[] = [];

    props.documentTemplate?.viewTemplates.forEach((viewTemplate) => {
      viewTemplate.imageTemplates.forEach((imageTemplate) => {
        if (imageTemplate.id && imageTemplate.isDefault) {
          const selection = selections.find(
            (selection) => selection.viewTemplateId === viewTemplate.id,
          );

          selection && selection.imageTemplateIds
            ? selection.imageTemplateIds.push(imageTemplate.id)
            : selections.push({
                viewTemplateId: viewTemplate.id,
                imageTemplateIds: [imageTemplate.id],
              });
        }
      });
    });

    props.document?.views.forEach((view) => {
      view.images?.forEach((image) => {
        const viewIndex = selections.findIndex(
          (state) => state.viewTemplateId === view.viewTemplateId,
        );

        if (viewIndex === -1) {
          selections.push({
            viewTemplateId: view.viewTemplateId,
            imageTemplateIds: [image.imageTemplateId],
          });
        } else {
          const index = selections.findIndex((state) =>
            state.imageTemplateIds?.includes(image.imageTemplateId),
          );

          if (index === -1) {
            selections[viewIndex].imageTemplateIds?.push(image.imageTemplateId);
          }
        }
      });
    });

    return selections;
  };

  const [selectedTemplates, setSelectedTemplates] = useState<{
    templates: SelectedViewAndImageTemplate[];
  }>({ templates: InitialTemplates() });

  const documentHasView = (viewTemplateId?: string): boolean => {
    return (
      !props.document ||
      props.document?.views.find((view) => view.viewTemplateId === viewTemplateId) !== undefined
    );
  };

  const documentHasImage = (viewTemplateId?: string, imageTemplateId?: string): boolean => {
    if (!props.document) {
      return true;
    }

    const view = props.document.views.find((view) => view.viewTemplateId === viewTemplateId);

    return view?.images?.find((image) => image.imageTemplateId === imageTemplateId) !== undefined;
  };

  const toggleViewOrImage = (viewTemplateId?: string, imageTemplateId?: string) => {
    const viewIndex =
      selectedTemplates.templates?.findIndex((state) => state.viewTemplateId === viewTemplateId) ??
      -1;

    const adjustedValues = selectedTemplates.templates?.slice(0) ?? [];
    if (viewIndex === -1) {
      adjustedValues.push({
        viewTemplateId: viewTemplateId,
        imageTemplateIds: imageTemplateId ? [imageTemplateId] : [],
      });
    } else if (imageTemplateId === null || imageTemplateId === undefined) {
      adjustedValues.splice(viewIndex, 1);
    } else {
      const index = adjustedValues[viewIndex].imageTemplateIds?.findIndex(
        (image) => image === imageTemplateId,
      );

      if (index === -1) {
        adjustedValues[viewIndex].imageTemplateIds?.push(imageTemplateId ?? "");
      } else {
        adjustedValues[viewIndex].imageTemplateIds?.splice(index as number, 1);
      }
    }

    setSelectedTemplates((prevState) => ({
      ...prevState,
      templates: adjustedValues,
    }));
  };

  return (
    <React.Fragment>
      <div className={`${styles["step-content"]} ${styles["step-views"]}`}>
        <Title level={3}>{t("common:viewsAndImages")}</Title>
        {props.documentTemplate?.viewTemplates.map((viewTemplate) => {
          const checkedView = !!selectedTemplates.templates.find(
            (template) => template.viewTemplateId === viewTemplate.id,
          );

          return (
            <Card
              key={viewTemplate.id}
              type="inner"
              title={
                <Checkbox
                  checked={checkedView}
                  disabled={!documentHasView(viewTemplate.id)}
                  onChange={() => toggleViewOrImage(viewTemplate.id, undefined)}
                >
                  {capitalizeFirstLetter(getLocalizedValue(viewTemplate.caption) ?? "")}
                </Checkbox>
              }
            >
              {viewTemplate.imageTemplates.map((imageTemplate) => {
                const checked = !!selectedTemplates.templates?.find((template) =>
                  template.imageTemplateIds?.includes(imageTemplate.id ?? ""),
                );

                return (
                  <Checkbox
                    key={imageTemplate.id}
                    checked={checked}
                    disabled={!documentHasImage(viewTemplate.id, imageTemplate.id)}
                    onChange={() => toggleViewOrImage(viewTemplate.id, imageTemplate.id)}
                  >
                    {capitalizeFirstLetter(getLocalizedValue(imageTemplate.caption) ?? "")}
                  </Checkbox>
                );
              })}
            </Card>
          );
        })}
      </div>
      <div className={styles["button-bar"]}>
        <Button type="primary" icon={<LeftOutlined />} onClick={props.onPrevious}>
          {t("common:previous")}
        </Button>
        <Button
          className={"button-next"}
          type="primary"
          icon={<RightOutlined />}
          disabled={!selectedTemplates.templates || selectedTemplates.templates?.length === 0}
          onClick={() => props.onNext(selectedTemplates.templates)}
        >
          {t("common:next")}
        </Button>
      </div>
    </React.Fragment>
  );
};
