import { GroupType } from "../../queries/groups/group-types";

export const getEntityForAuthorization = (groupType: GroupType) => {
  switch (groupType) {
    case GroupType.Attribute:
      return "attribute";
    case GroupType.Field:
      return "field";
    case GroupType.SecurityFeature:
      return "securityFeature";
    case GroupType.DocumentTemplate:
      return "documentTemplate";
  }
};
