import { Divider, Statistic } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { CmsPageLoader } from "./common/PageComponents";
import { useQueryStatistics } from "../queries/statistics/lists";

export const CmsDashboard = () => {
  const { t } = useTranslation();
  const { data: publishedDocuments, isLoading: isLoadingPublishedDocuments } =
    useQueryStatistics("PublishedDocuments");
  const { data: publishedImages, isLoading: isLoadingPublishedImages } =
    useQueryStatistics("PublishedImages");

  return (
    <React.Fragment>
      <CmsPageLoader
        loading={isLoadingPublishedDocuments || isLoadingPublishedImages}
        subTitle={t("common:loadingData")}
      >
        {publishedDocuments && (
          <React.Fragment>
            <Divider orientation="left">{t("common:numberOfDocumentsPublished")}</Divider>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                flexWrap: "wrap",
              }}
            >
              {publishedDocuments.map((publishedDocument, index) => (
                <div
                  key={`div_${index}`}
                  style={{
                    width: "200px",
                    padding: "20px",
                    marginRight: "20px",
                    marginBottom: "20px",
                    backgroundColor: "#ebebeb",
                  }}
                >
                  <Statistic
                    title={publishedDocument.name}
                    value={publishedDocument.count}
                    precision={0}
                    groupSeparator="."
                  />
                </div>
              ))}
            </div>
          </React.Fragment>
        )}

        {publishedImages && (
          <React.Fragment>
            <Divider orientation="left">{t("common:numberOfImagesPublished")}</Divider>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                flexWrap: "wrap",
              }}
            >
              {publishedImages.map((publishedImage, index) => (
                <div
                  key={`div_${index}`}
                  style={{
                    width: "200px",
                    padding: "20px",
                    marginRight: "20px",
                    marginBottom: "20px",
                    backgroundColor: "#ebebeb",
                  }}
                >
                  <Statistic
                    title={publishedImage.name}
                    value={publishedImage.count}
                    precision={0}
                    groupSeparator="."
                  />
                </div>
              ))}
            </div>
          </React.Fragment>
        )}
      </CmsPageLoader>
    </React.Fragment>
  );
};
