import { createContext, FC, useContext, useState } from "react";
import { BreadcrumbItem, CurrentUser } from "./context-types";
import config from "../../Config";

export const CmsContext = createContext<CmsContextProps | null>(null);

export const CmsContextProvider: FC = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<CurrentUser>(null);
  const [breadcrumbItems, setBreadcrumbItems] = useState<BreadcrumbItem[]>([]);
  const [duplicateErrorMessage, setDuplicateErrorMessage] = useState<string | undefined>(undefined);
  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);
  const [multiLangualInputActive, setMultiLangualInputActive] = useState<string>(
    config.language.toLowerCase(),
  );
  const [multiLangualCompareView, setMultiLangualCompareView] = useState<boolean>(false);
  const [documentSeriesFilterPanel, setDocumentSeriesFilterPanel] = useState<boolean>(true);
  const [saveEditMode, setSaveEditMode] = useState<boolean>(true);
  const [autoSave, setAutoSave] = useState<boolean>(false);

  return (
    <CmsContext.Provider
      value={{
        currentUser,
        setCurrentUser,
        breadcrumbItems,
        setBreadcrumbItems,
        duplicateErrorMessage,
        setDuplicateErrorMessage,
        unsavedChanges,
        setUnsavedChanges,
        multiLangualInputActive,
        setMultiLangualInputActive,
        multiLangualCompareView,
        setMultiLangualCompareView,
        documentSeriesFilterPanel,
        setDocumentSeriesFilterPanel,
        saveEditMode,
        setSaveEditMode,
        autoSave,
        setAutoSave,
      }}
    >
      {children}
    </CmsContext.Provider>
  );
};

export const useCmsContext = () => useContext(CmsContext);

type CmsContextProps = {
  currentUser: CurrentUser;
  setCurrentUser: (currentUser: CurrentUser) => void;
  breadcrumbItems: BreadcrumbItem[];
  setBreadcrumbItems: (breadcrumbs: BreadcrumbItem[]) => void;
  duplicateErrorMessage: string | undefined;
  setDuplicateErrorMessage: (message: string) => void;
  unsavedChanges: boolean;
  setUnsavedChanges: (value: boolean) => void;
  multiLangualInputActive: string;
  setMultiLangualInputActive: (language: string) => void;
  multiLangualCompareView: boolean;
  setMultiLangualCompareView: (value: boolean) => void;
  documentSeriesFilterPanel: boolean;
  setDocumentSeriesFilterPanel: (value: boolean) => void;
  saveEditMode: boolean;
  setSaveEditMode: (value: boolean) => void;
  autoSave: boolean;
  setAutoSave: (value: boolean) => void;
};
