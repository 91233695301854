import { Breadcrumb } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useRouteMatch } from "react-router-dom";
import { useCmsContext } from "../context/app/CmsContext";
import { DocumentTemplateEntity } from "../models/templates/DocumentTemplate";

export const CmsBreadcrumb = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const match = useRouteMatch({
    path: ["/document-series/:documentTemplateId/:id", "/document-series/:documentTemplateId"],
    exact: true,
    strict: true,
  });

  const [itemsState, setItemsState] = useState<BreadcrumbItem[]>([]);

  const context = useCmsContext();

  const config = [
    {
      name: t("entities:attributes"),
      forRoutes: ["attributes", "attribute"],
      routeTo: "/attributes",
    },
    {
      name: t("entities:countries"),
      forRoutes: ["countries", "country"],
      routeTo: "/countries",
    },
    {
      name: t("entities:currencies"),
      forRoutes: ["currencies", "currency"],
      routeTo: "/currencies",
    },
    {
      name: t("entities:documentTemplates"),
      forRoutes: ["document-templates", "document-template"],
      routeTo: "/document-templates",
    },
    {
      name: t("entities:documentTypes"),
      forRoutes: ["document-types", "document-type"],
      routeTo: "/document-types",
    },
    {
      name: t("entities:documentSeries"),
      forRoutes: ["document-series", "document-wizard"],
      routeTo: `/document-series/${(match?.params as DocumentTemplateEntity)?.documentTemplateId}`,
    },
    {
      name: t("entities:fields"),
      forRoutes: ["fields", "field"],
      routeTo: "/fields",
    },
    {
      name: t("entities:languages"),
      forRoutes: ["languages", "language"],
      routeTo: "/languages",
    },
    {
      name: t("entities:materialTypes"),
      forRoutes: ["material-types", "material-type"],
      routeTo: "/material-types",
    },
    {
      name: t("entities:organizations"),
      forRoutes: ["organizations", "organization"],
      routeTo: "/organizations",
    },
    {
      name: t("entities:regions"),
      forRoutes: ["regions", "region"],
      routeTo: "/regions",
    },
    {
      name: t("entities:securityFeatures"),
      forRoutes: ["security-features", "security-feature"],
      routeTo: "/security-features",
    },
    {
      name: t("common:translations"),
      forRoutes: ["translations"],
      routeTo: "/translations",
    },
    {
      name: t("common:imageUpload"),
      forRoutes: ["image-upload"],
      routeTo: "/image-upload",
    },
  ];

  useEffect(() => {
    const items = [] as BreadcrumbItem[];

    context?.setBreadcrumbItems([]);

    const segments = pathname.split("/");
    const component =
      segments.length > 1
        ? config.find((c) => c.forRoutes.indexOf(segments[1].toLowerCase()) > -1)
        : undefined;

    if (component) {
      items.push({
        title: component.name,
        href: component.routeTo,
      });
    }

    setItemsState(items);
  }, [pathname]);

  useEffect(() => {
    if (context?.breadcrumbItems) {
      const items = [] as BreadcrumbItem[];

      context?.breadcrumbItems.forEach((item) => {
        if (itemsState.every((stateItem) => stateItem.title !== item.name))
          items.push({
            title: item.name,
          });
      });
      setItemsState((prevState) => [...prevState, ...items]);
    }
  }, [context?.breadcrumbItems]);

  return (
    <Breadcrumb
      style={{ marginBottom: "16px" }}
      separator={"/"}
      items={[{ title: "Home", href: "/" }, ...itemsState]}
    />
  );
};

type BreadcrumbItem = { title: string; href?: string };
