import { useQuery } from "@tanstack/react-query";
import api from "../../services/api";
import { CONST_DOCUMENT_TEMPLATES_GROUPS } from "../../utilities/constants/query-contants";
import { Group } from "../../models/templates/Group";

export const getDocumentTemplateGroups = (): Promise<Group[]> => {
  return api.get({
    path: CONST_DOCUMENT_TEMPLATES_GROUPS.plural,
  });
};

export const useQueryDocumentTemplateGroups = () => {
  return useQuery<Group[]>([CONST_DOCUMENT_TEMPLATES_GROUPS.plural], () =>
    getDocumentTemplateGroups(),
  );
};
