import { TFunction } from "i18next";
import { Region } from "../../models/reference_lists/Region";
import { validateMLInput, validateRegEx, validateRequired } from "../Validators";

export const ValidateRegion = (region: Region, t: TFunction) => {
  const errors: any = {};

  const nameResult = validateMLInput(region.name, t("properties:name"));
  if (nameResult != null) {
    errors.name = nameResult;
  }

  const isoCodeResult =
    validateRequired(region.isoCode, t("properties:isoCode")) ||
    validateRegEx(
      region.isoCode,
      /^[a-zA-Z]{1,50}$/,
      t("validations:regionIsoCode", { property: t("properties:isoCode") }),
    );
  if (isoCodeResult != null) {
    errors.isoCode = isoCodeResult;
  }

  return errors;
};
