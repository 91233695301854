import { Modal, Popconfirm, Select } from "antd";
import { useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Config from "../../../Config";
import { useAuthorization } from "../../../hooks/useAuthorization";
import { DocumentSeries } from "../../../models/data/Document";
import { Image } from "../../../models/data/Image";
import { SecurityFeatureSelection } from "../../../models/data/SecurityFeatureSelection";
import { View } from "../../../models/data/View";
import { DocumentTemplate } from "../../../models/templates/DocumentTemplate";
import { capitalizeFirstLetter } from "../../../utilities/StringHelper";
import { CmsCancelButton, CmsSaveButton } from "../../common/ButtonComponents";
import { CmsSelect } from "../../common/FormComponents";
import { Instruction } from "../../../models/data/Instruction";
import { getDocumentTemplate } from "../../../queries/document-template/detail";
import { getSecurityFeature } from "../../../queries/security-features/detail";

interface Props {
  onClose: (instructions?: Instruction[]) => void;
  securityFeatureSelection: SecurityFeatureSelection;
  viewId: string;
}

type ExtendProperties<T extends {}> = T & {
  name?: string;
};

interface Selection {
  viewId?: string;
  isLoadingView: boolean;
  imageId?: string;
  isLoadingImage: boolean;
  securityFeatureSelectionId?: string;
  isLoadingSecurityFeature: boolean;
  documentTemplate?: DocumentTemplate;
  views: Array<ExtendProperties<View>> | null;
  images: Array<ExtendProperties<Image>> | null;
  securityFeatureSelections: Array<ExtendProperties<SecurityFeatureSelection>> | null;
}

export default function SecurityFeatureInstructionCopyModal(props: Props) {
  const { canUpdate } = useAuthorization("document");
  const { t } = useTranslation();
  const [state, setState] = useState<Selection>({
    viewId: undefined,
    isLoadingView: true,
    imageId: undefined,
    isLoadingImage: true,
    securityFeatureSelectionId: undefined,
    isLoadingSecurityFeature: true,
    documentTemplate: undefined,
    views: null,
    images: null,
    securityFeatureSelections: null,
  });
  const defaultLanguage = Config.language;

  const { values: document } = useFormikContext<DocumentSeries>();

  const copy = () => {
    const viewIndex = document.views.findIndex((view) => view.id === state.viewId);
    const imageindex = document.views[viewIndex].images?.findIndex(
      (image) => image.id === state.imageId,
    );

    if (imageindex === undefined || viewIndex === undefined) return;

    const securityFeatureSelection = document.views[viewIndex].images?.[
      imageindex
    ].securityFeatureSelections?.find(
      (securityFeatureSelection) =>
        securityFeatureSelection.id === state.securityFeatureSelectionId,
    );

    const formatted = securityFeatureSelection?.instructions?.map((instruction) => {
      return {
        caption: instruction.caption,
        text: instruction.text,
        order: instruction.order,
      } as Instruction;
    });

    props.onClose(formatted);
  };

  useEffect(() => {
    (async () => {
      if (!document.documentTemplateId) return;

      const documentTemplate = await getDocumentTemplate(document.documentTemplateId);

      const views = document.views
        .filter((view) => view.id !== props.viewId)
        .map((view) => {
          const viewTemplateName = documentTemplate.viewTemplates.find(
            (viewTemplate) => viewTemplate.id === view.viewTemplateId,
          )?.caption;

          if (viewTemplateName) {
            const captionedViewTemplateName =
              viewTemplateName[defaultLanguage] ?? viewTemplateName["en"];

            return {
              ...view,
              name: captionedViewTemplateName,
            };
          } else {
            return view;
          }
        });

      setState((prevState) => ({
        ...prevState,
        views: views,
        documentTemplate: documentTemplate,
        isLoadingView: false,
      }));
    })();
    // eslint-disable-next-line
  }, []);

  const processViewSelection = async (viewId: string) => {
    const view = state.views?.find((view) => view.id === viewId);

    const images = view?.images?.map((image) => {
      const imageTemplateName = state.documentTemplate?.viewTemplates
        .find((viewTemplate) => viewTemplate.id === view.viewTemplateId)
        ?.imageTemplates.find(
          (imageTemplate) => imageTemplate.id === image.imageTemplateId,
        )?.caption;

      if (!imageTemplateName) {
        return image;
      }

      const captionedImageTemplateName =
        imageTemplateName[defaultLanguage] ?? imageTemplateName["en"];

      return {
        ...image,
        name: captionedImageTemplateName,
      };
    });

    setState((prevState) => ({
      ...prevState,
      viewId: viewId,
      images: images ?? null,
      imageId: undefined,
      isLoadingImage: false,
      securityFeatureSelectionId: undefined,
      isLoadingSecurityFeature: true,
    }));
  };

  const processImageSelection = async (imageId: string) => {
    const securityFeatureSelections = state.images?.find(
      (image) => image.id === imageId,
    )?.securityFeatureSelections;

    if (!securityFeatureSelections) return;

    const securityFeatureSelectionsCaptioned = await Promise.all(
      securityFeatureSelections.map(async (securityFeatureSelection) => {
        const securityFeature = await getSecurityFeature(
          securityFeatureSelection.securityFeatureId,
        );

        const captionedSecurityFeatureName = `${
          securityFeature.caption[defaultLanguage] ?? securityFeature.caption["en"]
        } ${securityFeatureSelection.order + 1}`;

        return {
          ...securityFeatureSelection,
          name: captionedSecurityFeatureName,
        };
      }),
    );

    setState((prevState) => ({
      ...prevState,
      imageId: imageId,
      securityFeatureSelections: securityFeatureSelectionsCaptioned,
      securityFeatureSelectionId: undefined,
      isLoadingSecurityFeature: false,
    }));
  };

  return (
    <Modal
      width={500}
      key={"copyInstructionsSecuritySelection"}
      title={t("common:copyInstructionsFromAnotherView")}
      open={true}
      closable={true}
      onCancel={() => props.onClose()}
      footer={[
        <CmsCancelButton key="cancel" onClick={() => props.onClose()} />,
        canUpdate && (
          <Popconfirm
            key={"confirmPopup"}
            title={t("common:confirmCopy")}
            okText={t("common:yes")}
            cancelText={t("common:no")}
            disabled={
              state.viewId === undefined ||
              state.imageId === undefined ||
              state.securityFeatureSelectionId === undefined
            }
            onConfirm={() => copy()}
          >
            <CmsSaveButton key="save" />
          </Popconfirm>
        ),
      ]}
    >
      {!state.isLoadingView && (
        <CmsSelect
          key={"viewSelector"}
          label={capitalizeFirstLetter(t("properties:view"))}
          required={true}
          placeholder={capitalizeFirstLetter(t("common:selectView"))}
          allowClear={false}
          onChange={(viewId) => processViewSelection(viewId)}
          children={state.views?.map((view, index) => (
            <Select.Option
              key={`field_${index}`}
              value={view.id as string}
              style={{ width: "300px" }}
            >
              {view.name}
            </Select.Option>
          ))}
        />
      )}

      {!state.isLoadingImage && (
        <CmsSelect
          key={"imageSelector"}
          label={capitalizeFirstLetter(t("properties:image"))}
          required={true}
          placeholder={capitalizeFirstLetter(t("common:selectImage"))}
          allowClear={false}
          value={state.imageId || ""}
          onChange={(imageId) => processImageSelection(imageId)}
          children={state.images?.map((image, index) => (
            <Select.Option key={`field_${index}`} value={image.id ?? ""}>
              {image.name}
            </Select.Option>
          ))}
        />
      )}

      {!state.isLoadingSecurityFeature && (
        <CmsSelect
          key={"securityFeatureSelector"}
          label={capitalizeFirstLetter(t("properties:securityFeature"))}
          required={true}
          placeholder={capitalizeFirstLetter(t("common:selectsecurityFeature"))}
          allowClear={false}
          onChange={(securityFeatureSelectionId) =>
            setState((prevState) => ({
              ...prevState,
              securityFeatureSelectionId: securityFeatureSelectionId,
            }))
          }
          children={state.securityFeatureSelections?.map((securityFeatureSelection, index) => (
            <Select.Option key={`field_${index}`} value={securityFeatureSelection.id as string}>
              {securityFeatureSelection.name}
            </Select.Option>
          ))}
        />
      )}
    </Modal>
  );
}
