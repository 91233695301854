import { useQuery } from "@tanstack/react-query";
import api from "../../services/api";
import { CONST_SEARCH_SETTINGS } from "../../utilities/constants/query-contants";
import { SearchSetting } from "../../utilities/configs/DocumentStatisticsConfig";

export const getSearchSettings = (): Promise<SearchSetting[]> => {
  return api.get({
    path: CONST_SEARCH_SETTINGS.plural,
  });
};

export const useQuerySearchSettings = () => {
  return useQuery<SearchSetting[]>([CONST_SEARCH_SETTINGS.plural], () => getSearchSettings());
};
