import { useTranslation } from "react-i18next";
import { useNotification } from "../../hooks/useNotification";
import { Language } from "../../models/reference_lists/Language";
import api from "../../services/api";
import { useMutationBase } from "../useMutationBase";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { CONST_LANGUAGES } from "../../utilities/constants/query-contants";

export const getLanguage = (id?: string): Promise<Language> => {
  return api.get({
    path: `${CONST_LANGUAGES.plural}/${id}`,
  });
};

export const useQueryLanguage = (id?: string) => {
  return useQuery<Language>([CONST_LANGUAGES.singular, { id }], () => getLanguage(id), {
    enabled: !!id,
  });
};

export const postLanguage = (payload: Language): Promise<void> => {
  return api.post({ path: CONST_LANGUAGES.plural, body: { ...payload } });
};

export const useSaveLanguage = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { notifySuccess } = useNotification();

  return useMutationBase({
    mutationFn: (payload: Language) => postLanguage(payload),
    onSuccess: () => {
      queryClient.invalidateQueries([CONST_LANGUAGES.singular]);
      queryClient.invalidateQueries([CONST_LANGUAGES.plural]);

      notifySuccess(
        t("common:successSave", {
          entity: t("entities:language").toLocaleLowerCase(),
        }),
      );
    },
  });
};

export const deleteLanguage = (id: string) => {
  return api.delete({ path: `${CONST_LANGUAGES.plural}/${id}` });
};

export const useDeleteLanguage = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { notifySuccess } = useNotification();

  return useMutationBase({
    mutationFn: (id: string) => deleteLanguage(id),
    onSuccess: () => {
      queryClient.invalidateQueries([CONST_LANGUAGES.singular]);
      queryClient.invalidateQueries([CONST_LANGUAGES.plural]);

      notifySuccess(
        t("common:successDelete", {
          entity: t("common:language").toLocaleLowerCase(),
        }),
      );
    },
  });
};
