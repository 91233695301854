import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import config from "../../Config";
import styles from "./Hologram.module.scss";
import { CmsPageLoader } from "./PageComponents";
import { useQueryHologram } from "../../queries/holograms/detail";

export const HologramImage = ({ fileId }: { fileId: string }) => {
  const { t } = useTranslation();
  const hologramElement = useRef<HTMLDivElement>(null);
  const [state, setState] = useState<{
    hologramWidth: number;
    hologramHeight: number;
    visibleImageIndex: number;
  }>({
    hologramWidth: 0,
    hologramHeight: 0,
    visibleImageIndex: -1,
  });

  const { data: hologram, isLoading: isLoadingHologram } = useQueryHologram(fileId);

  const { images, minX, maxX, minY, maxY } = useMemo(() => {
    const images = Array<{ fileName: string; x: number; y: number }>();
    let minX = 0,
      maxX = 0,
      minY = 0,
      maxY = 0;

    if (!!hologram) {
      // Parse coordinates of images.
      var regex: RegExp = new RegExp(
        "^.+_[0-9]{3}_(-?[0-9]+)(?:,([0-9]+))?(?:_(-?[0-9]+)(?:,([0-9]+))?)?\\..+$",
      );
      hologram.imageFileNames.forEach((fileName) => {
        const matches = fileName.match(regex);
        if (matches) {
          const hasYCoordinate = matches[3] !== undefined;
          const x = -Number(
            matches[hasYCoordinate ? 3 : 1] + "." + (matches[hasYCoordinate ? 4 : 2] || 0),
          );
          const y = hasYCoordinate ? Number(matches[1] + "." + (matches[2] || 0)) : 0;

          if (x < minX) {
            minX = x;
          }
          if (y < minY) {
            minY = y;
          }
          if (x > maxX) {
            maxX = x;
          }
          if (y > maxY) {
            maxY = y;
          }

          images.push({ fileName, x, y });
        }
      });
    }

    return { images, minX, maxX, minY, maxY };
  }, [hologram]);

  const getClosest = useCallback(
    (x, y) => {
      // Map relative x/y to imagespace.
      const effX = minX + x * (maxX - minX);
      const effY = minY + y * (maxY - minY);
      // Find the image which is closest in x/y.
      const distance = images.map((image) => {
        // Calculate squared distances.
        return Math.pow(image.x - effX, 2) + Math.pow(image.y - effY, 2);
      });
      // Return the closest image index.
      return distance.indexOf(Math.min(...distance));
    },
    [images, minX, maxX, minY, maxY],
  );

  useEffect(() => {
    const closest = getClosest(0, 0);
    setState((prevState) => ({ ...prevState, visibleImageIndex: closest }));
  }, [getClosest]);

  const mouseMove = (event: any) => {
    const { current } = hologramElement;

    if (current) {
      const { width, height, top, left } = current.getBoundingClientRect();
      const xfrac = (event.clientX - left) / Math.max(1, width);
      const yfrac = (event.clientY - top) / Math.max(1, height);
      const closest = getClosest(xfrac, yfrac);
      if (closest !== state.visibleImageIndex) {
        setState((prevState) => ({ ...prevState, visibleImageIndex: closest }));
      }
    }
  };

  return (
    <CmsPageLoader loading={isLoadingHologram}>
      <div
        ref={hologramElement}
        onMouseMove={mouseMove}
        className={styles["hologram"]}
        style={{ width: state.hologramWidth, height: state.hologramHeight }}
      >
        {images.map((image, index) => {
          return (
            <img
              key={index}
              src={`${config.apiUrl}hologram/${hologram?.fileId}/cache/${image.fileName}`}
              className={
                index === state.visibleImageIndex ? styles["visible"] : styles["invisible"]
              }
              alt=""
              onLoad={(e) => {
                if (index === 0) {
                  const width = e.currentTarget.naturalWidth;
                  const height = e.currentTarget.naturalHeight;
                  setState((prevState) => ({
                    ...prevState,
                    hologramWidth: width,
                    hologramHeight: height,
                  }));
                }
              }}
            />
          );
        })}
      </div>
      {hologram?.imageFileNames.length !== images.length ? (
        <div className={styles["image-warning"]}>{t("errors:hologramImagesNotLoaded")}</div>
      ) : null}
    </CmsPageLoader>
  );
};
