import { TFunction } from "i18next";
import { Attribute } from "../../models/templates/Attribute";
import { validateName, validateRequired } from "../Validators";
import { AttributeValue } from "../../models/data/AttributeValue";
import { AttributeLink } from "../../models/templates/AttributeLink";
import _ from "lodash";

export const ValidateAttribute = (attribute: Attribute, t: TFunction) => {
  const errors: any = {};

  const groupIdResult = validateRequired(attribute.groupId, t("properties:group"));
  if (groupIdResult != null) {
    errors.groupId = groupIdResult;
  }

  const dataTypeResult = validateRequired(attribute.dataType, t("properties:type"));
  if (dataTypeResult != null) {
    errors.dataType = dataTypeResult;
  }

  const nameResult =
    validateRequired(attribute.name, t("properties:name")) ||
    validateName(attribute.name, t("properties:name"));
  if (nameResult != null) {
    errors.name = nameResult;
  }

  return errors;
};

export const getAttributeNames = (
  attributeValues: AttributeValue[],
  attributeLinks: AttributeLink[],
  attributes?: Attribute[],
) => {
  const attributeNames = [] as string[];
  _.orderBy(attributeLinks, "order", "asc").forEach((attributeLink) => {
    const attributeValue = attributeValues.find(
      (attributeValue) => attributeValue.attributeLinkId === attributeLink.id,
    );
    if (attributeValue && attributeValue.value === "true") {
      //eslint-disable-next-line
      const attribute = attributes?.[attributeLink.attributeId];
      if (attribute?.name) attributeNames.push(attribute.name);
    }
  });
  return attributeNames.join(", ");
};
