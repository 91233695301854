import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useAuthorization } from "../../../hooks/useAuthorization";
import { Language } from "../../../models/reference_lists/Language";
import { CmsCreateButton } from "../../common/ButtonComponents";
import { CmsPageHeader, CmsSearch, CmsTable } from "../../common/PageComponents";
import { useDeleteLanguage } from "../../../queries/languages/detail";
import { useQueryLanguages } from "../../../queries/languages/lists";
import { useCmsContext } from "../../../context/app/CmsContext";

export const LanguageList = () => {
  const { t } = useTranslation();
  const { canCreate, canDelete, canUpdate, canView } = useAuthorization("language");
  const { push } = useHistory();
  const context = useCmsContext();
  const {
    data: languages,
    isLoading: isLoadingLanguages,
    refetch: refetchLanguages,
  } = useQueryLanguages();
  const { mutateAsync: deleteLanguage, isLoading: isDeletingLanguage } = useDeleteLanguage();

  const [languagesFilter, setLanguagesFilter] = useState<Language[]>(languages ?? []);

  const search = (searchText) => {
    if (languages) {
      const search = searchText.toLowerCase();
      const filteredLanguages = languages.filter((language) => {
        const name = language.name && language.name.toLowerCase();
        const iso = language.isoCode && language.isoCode.toLowerCase();
        return (name && name.includes(search)) || (iso && iso.includes(search));
      });

      setLanguagesFilter(filteredLanguages);
    }
  };

  useEffect(() => {
    if (!!languages) {
      setLanguagesFilter(languages);
    }
  }, [languages]);

  useEffect(() => {
    if (languagesFilter) {
      context?.setDuplicateErrorMessage(t("errors:languageLinked"));
    }
  }, [languagesFilter, t]);

  return (
    <React.Fragment>
      <CmsPageHeader
        title={t("entities:languages")}
        extra={[
          <CmsSearch key="search" onChange={search} />,
          canCreate ? <CmsCreateButton key="create" onClick={() => push(`/language`)} /> : null,
        ]}
      />
      <CmsTable
        loading={isLoadingLanguages || isDeletingLanguage}
        dataSource={languagesFilter}
        rowKey="id"
        columns={[
          { title: t("properties:name"), width: "30%", dataIndex: "name" },
          {
            title: t("properties:isoCode"),
            width: "20%",
            dataIndex: "isoCode",
          },
        ]}
        onEdit={canUpdate || canView ? (id) => push(`/language/${id}`) : undefined}
        onDelete={
          canDelete
            ? async (id) => {
                await deleteLanguage(id);
                await refetchLanguages();
              }
            : undefined
        }
      />
    </React.Fragment>
  );
};
