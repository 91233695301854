import { LeftOutlined } from "@ant-design/icons";
import { Button, Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { DocumentType } from "../../../../models/reference_lists/DocumentType";
import { MaterialType } from "../../../../models/reference_lists/MaterialType";
import { DocumentTemplate } from "../../../../models/templates/DocumentTemplate";
import styles from "./DocumentWizard.module.scss";
import { SelectedViewAndImageTemplate } from "./interfaces";
const { Title } = Typography;

export const StepSummary = (props: {
  documentTemplate?: DocumentTemplate;
  code?: string;
  documentType?: DocumentType;
  materialType?: MaterialType;
  selectedTemplates: SelectedViewAndImageTemplate[];
  isSavingDocument: boolean;
  onPrevious: () => void;
  onNext: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <div className={`${styles["step-content"]} ${styles["step-summary"]}`}>
        <Title level={3}>{t("common:summary")}</Title>
        <div className={styles["property"]}>
          <div className={styles["name"]}>{t("common:template")}:</div>
          {props.documentTemplate?.name}
        </div>
        <div className={styles["property"]}>
          <div className={styles["name"]}>{t("properties:code")}:</div>
          {props.code}
        </div>
        <div className={styles["property"]}>
          <div className={styles["name"]}>{t("properties:type")}:</div>
          {props.documentType?.name}
        </div>
        <div className={styles["property"]}>
          <div className={styles["name"]}>{t("properties:materialType")}:</div>
          {props.materialType?.name}
        </div>
        <div className={styles["property"]}>
          <div className={styles["name"]}>{t("common:viewsAndImages")}:</div>
          <div className={styles["views"]}>
            {props.selectedTemplates.map((template) => {
              const viewTemplate = props.documentTemplate?.viewTemplates.find(
                (viewTemplate) => viewTemplate.id === template.viewTemplateId,
              );

              const imageTemplateNames = viewTemplate?.imageTemplates
                .filter((image) => template.imageTemplateIds?.includes(image.id ?? ""))
                .map((image) => image.name);

              return (
                imageTemplateNames && (
                  <div key={viewTemplate?.id} className={styles["view"]}>
                    {viewTemplate?.name}
                    <br />
                    <span className={styles["image-names"]}>{imageTemplateNames.join(", ")}</span>
                  </div>
                )
              );
            })}
          </div>
        </div>
      </div>
      <div className={styles["button-bar"]}>
        <Button type="primary" icon={<LeftOutlined />} onClick={props.onPrevious}>
          {t("common:previous")}
        </Button>
        <Button
          className={"button-next"}
          type="primary"
          loading={props.isSavingDocument}
          onClick={props.onNext}
        >
          {t("common:create")}
        </Button>
      </div>
    </React.Fragment>
  );
};
