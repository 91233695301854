import { Button, Flex, Modal, Tooltip } from "antd";
import { CmsCancelButton, CmsSaveButton } from "../../../common/ButtonComponents";
import Config from "../../../../Config";
import "cropperjs/dist/cropper.css";
import { useRef, useState } from "react";
import ReactCropper, { ReactCropperElement } from "react-cropper";
import ButtonGroup from "antd/es/button/button-group";
import {
  ForwardOutlined,
  CompressOutlined,
  FullscreenOutlined,
  RetweetOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
  SaveOutlined,
} from "@ant-design/icons";

export const CropperModal = ({
  fileId,
  open,
  preSelection,
  title,
  onClose,
  onSave,
}: {
  fileId: string;
  open: boolean;
  preSelection?: Cropper.Data;
  title?: string;
  onClose: () => void;
  onSave: (selection: Cropper.Data, another?: boolean) => void;
}) => {
  const cropperRef = useRef<ReactCropperElement>(null);
  const [selection, setSelection] = useState<Cropper.Data | undefined>(preSelection);

  return (
    <Modal
      width={1000}
      style={{ height: "750px", maxHeight: "80%" }}
      title={title ?? "Create/edit image crop"}
      open={open}
      closable={true}
      onCancel={() => onClose()}
      footer={[]}
      zIndex={1001}
    >
      <Flex align="center" justify="space-between">
        <div>
          <ButtonGroup style={{ margin: "2px" }}>
            <Tooltip title="Crop">
              <Button
                type="primary"
                icon={<CompressOutlined />}
                onClick={() => cropperRef.current?.cropper.setDragMode("crop")}
              />
            </Tooltip>
            <Tooltip title="Move">
              <Button
                type="primary"
                icon={<FullscreenOutlined />}
                onClick={() => cropperRef.current?.cropper.setDragMode("move")}
              />
            </Tooltip>
          </ButtonGroup>

          <ButtonGroup style={{ margin: "2px" }}>
            <Tooltip title="Reset">
              <Button
                type="primary"
                icon={<RetweetOutlined />}
                onClick={() => cropperRef.current?.cropper.reset()}
              />
            </Tooltip>
          </ButtonGroup>

          <ButtonGroup style={{ margin: "2px" }}>
            <Tooltip title="Zoom in">
              <Button
                type="primary"
                icon={<ZoomInOutlined />}
                onClick={() => cropperRef.current?.cropper.zoom(0.1)}
              />
            </Tooltip>
            <Tooltip title="Zoom out">
              <Button
                type="primary"
                icon={<ZoomOutOutlined />}
                onClick={() => cropperRef.current?.cropper.zoom(-0.1)}
              />
            </Tooltip>
          </ButtonGroup>
        </div>

        <div>
          <ButtonGroup>
            <CmsCancelButton key="cancel" onClick={() => onClose()} />
            <CmsSaveButton
              key="save"
              disabled={!selection}
              onClick={() => {
                if (!!selection) {
                  onSave(selection);
                }
              }}
            />
            <Tooltip title={"Save and create another"}>
              <Button
                type="dashed"
                onClick={() => {
                  if (!!selection) {
                    onSave(selection, true);
                    cropperRef.current?.cropper.clear();
                  }
                }}
              >
                <>
                  <SaveOutlined />
                  <ForwardOutlined />
                </>
              </Button>
            </Tooltip>
          </ButtonGroup>
        </div>
      </Flex>

      <div style={{ maxHeight: "600px" }}>
        <ReactCropper
          ref={cropperRef}
          id="selection-area"
          src={`${Config.apiUrl}file/${fileId}?imageSize=XL`}
          background={false}
          autoCrop={!!selection}
          style={{ display: "block", maxWidth: "100%", maxHeight: "600px" }}
          data={selection}
          crop={(crp) => setSelection(crp.detail)}
        />
      </div>
    </Modal>
  );
};
