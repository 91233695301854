import { Modal } from "antd";
import { useState } from "react";
import { Point } from "react-lasso-select/lib/helpers";
import { CmsCancelButton, CmsSaveButton } from "../../../common/ButtonComponents";
import ReactLassoSelect from "react-lasso-select";
import Config from "../../../../Config";

export const PolyCropperModal = ({
  fileId,
  open,
  preSelection,
  onClose,
  onSave,
}: {
  fileId: string;
  open: boolean;
  preSelection?: Point[];
  onClose: () => void;
  onSave: (selection: Point[]) => void;
}) => {
  const [selection, setSelection] = useState<Point[] | undefined>(preSelection);

  return (
    <Modal
      width={1000}
      style={{ height: "750px", maxHeight: "80%" }}
      title={"test"}
      open={open}
      closable={true}
      onCancel={() => onClose()}
      footer={[
        <CmsCancelButton key="cancel" onClick={() => onClose()} />,
        <CmsSaveButton
          key="save"
          disabled={!selection}
          onClick={() => {
            if (!!selection) {
              onSave(selection);
            }
          }}
        />,
      ]}
      zIndex={1001}
    >
      <ReactLassoSelect
        src={`${Config.apiUrl}file/${fileId}?imageSize=XL`}
        value={selection}
        imageStyle={{
          width: "425px",
        }}
        onChange={setSelection}
      />
    </Modal>
  );
};
