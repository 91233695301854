import { SecurityFeatureSelectionImage } from "../models/data/SecurityFeatureSelectionImage";
import update from "immutability-helper";

export const rearrangeOrder = (
  images: SecurityFeatureSelectionImage[],
): SecurityFeatureSelectionImage[] => {
  for (const [i] of images.entries()) {
    images = update(images, { [i]: { order: { $set: i + 1 } } });
  }
  return images;
};

export const generateSecurityFeatureSelectionPlaceholder = (
  images: SecurityFeatureSelectionImage[],
): SecurityFeatureSelectionImage[] => {
  if (images.length >= 26) {
    return images;
  }

  const image: SecurityFeatureSelectionImage = {
    fileId: null,
    order: images.length + 1,
  };

  const imagesList = update(images || [], { $push: [image] });
  return rearrangeOrder(imagesList);
};
