import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Attribute } from "../../models/templates/Attribute";
import api from "../../services/api";
import { useTranslation } from "react-i18next";
import { useNotification } from "../../hooks/useNotification";
import { useMutationBase } from "../useMutationBase";
import { CONST_ATTRIBUTES } from "../../utilities/constants/query-contants";

export const getAttribute = (id?: string): Promise<Attribute> => {
  return api.get({
    path: `${CONST_ATTRIBUTES.plural}/${id}`,
  });
};

export const useQueryAttribute = (id?: string) => {
  return useQuery<Attribute>([CONST_ATTRIBUTES.singular, { id }], () => getAttribute(id), {
    enabled: !!id,
  });
};

export const postAttribute = (payload: Attribute): Promise<void> => {
  return api.post({ path: CONST_ATTRIBUTES.plural, body: { ...payload } });
};

export const useSaveAttribute = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { notifySuccess } = useNotification();

  return useMutationBase({
    mutationFn: (payload: Attribute) => postAttribute(payload),
    onSuccess: () => {
      queryClient.invalidateQueries([CONST_ATTRIBUTES.singular]);
      queryClient.invalidateQueries([CONST_ATTRIBUTES.plural]);

      notifySuccess(
        t("common:successSave", {
          entity: t("entities:attribute").toLocaleLowerCase(),
        }),
      );
    },
  });
};

export const deleteAttribute = (id: string) => {
  return api.delete({ path: `${CONST_ATTRIBUTES.plural}/${id}` });
};

export const useDeleteAttribute = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { notifySuccess } = useNotification();

  return useMutationBase({
    mutationFn: (id: string) => deleteAttribute(id),
    onSuccess: () => {
      queryClient.invalidateQueries([CONST_ATTRIBUTES.singular]);
      queryClient.invalidateQueries([CONST_ATTRIBUTES.plural]);

      notifySuccess(
        t("common:successDelete", {
          entity: t("common:attribute").toLocaleLowerCase(),
        }),
      );
    },
  });
};
