import { LoadingOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, Form, Input, Popover, Spin, Table, Upload } from "antd";
import Modal from "antd/lib/modal/Modal";
import { RcFile } from "antd/lib/upload";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import config from "../../../Config";
import { useAuthorization } from "../../../hooks/useAuthorization";
import { Note } from "../../../models/data/Note";
import { IsImage } from "../../../utilities/FileUtil";
import { CmsModalForm } from "../../common/FormComponents";
import { RowButtonSet } from "../../common/RowButtonSet";
import { validateNote } from "../../../utilities/helpers/note-helper";
import React from "react";
import { useUploadFile } from "../../../queries/files/detail";

export const NoteDetails = (props: {
  note: Note;
  onClose: () => void;
  onSave: (note: Note) => void;
}) => {
  const { canUpdate } = useAuthorization("document");
  const { t } = useTranslation();
  const [state, setState] = React.useState<{
    files: RcFile[];
    isLoading: boolean;
    isChanged: boolean;
  }>({
    files: [],
    isLoading: false,
    isChanged: false,
  });

  const { mutateAsync: importNote, isLoading: isImportingNote } = useUploadFile();

  if (props.note == null) {
    return null;
  } else {
    return (
      <Formik
        initialValues={props.note}
        enableReinitialize={true}
        validate={(note: Note) => validateNote(note, t)}
        onSubmit={async (note: Note) => {
          if (state.files) {
            for (const file of state.files) {
              const response = await importNote({ file });

              note.attachments?.push({
                fileId: response.id,
                fileName: response.fileName,
              });
            }

            props.onSave(note);
          }
        }}
      >
        {(formikProps) => {
          const { values, handleSubmit, setFieldValue, errors, isValid } = formikProps;

          return (
            <Modal
              open={true}
              width={700}
              title={t("entities:note")}
              onCancel={() => {
                props.onClose();
              }}
              destroyOnClose={true}
              footer={[
                <Button
                  id="btnCancel"
                  key="cancel"
                  onClick={() => {
                    props.onClose();
                  }}
                >
                  {t("common:cancel")}
                </Button>,
                <Button
                  id="btnSave"
                  key="save"
                  type="primary"
                  disabled={!isValid || !state.isChanged}
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  {t("common:save")}
                </Button>,
              ]}
            >
              <React.Fragment>
                <Spin
                  spinning={isImportingNote}
                  indicator={<LoadingOutlined style={{ fontSize: 24, marginBottom: 20 }} spin />}
                  tip="Uploading files..."
                >
                  <React.Fragment>
                    <CmsModalForm>
                      <Form.Item
                        label={t("properties:title")}
                        validateStatus={!errors.title ? "success" : "error"}
                        help={errors.title}
                        required={true}
                      >
                        <Input
                          readOnly={!canUpdate}
                          id="title"
                          type="text"
                          placeholder={t("properties:title")}
                          style={{ width: "435px" }}
                          maxLength={250}
                          value={values.title}
                          onChange={(e) => {
                            setFieldValue("title", e.target.value);
                            setState((prevState) => ({
                              ...prevState,
                              isChanged: true,
                            }));
                          }}
                        />
                      </Form.Item>
                      <Form.Item
                        label={t("properties:description")}
                        validateStatus={!errors.description ? "success" : "error"}
                        help={errors.description}
                        required={true}
                      >
                        <Input.TextArea
                          readOnly={!canUpdate}
                          rows={5}
                          id="description"
                          placeholder={t("properties:description")}
                          style={{ width: "435px" }}
                          maxLength={1000}
                          value={values.description}
                          onChange={(e) => {
                            setFieldValue("description", e.target.value);
                            setState((prevState) => ({
                              ...prevState,
                              isChanged: true,
                            }));
                          }}
                        />
                      </Form.Item>

                      <Form.Item label={t("properties:attachments")}>
                        <Table
                          dataSource={values.attachments}
                          rowKey={(row, index) => row.id || (index as number)}
                          bordered
                          size={"small"}
                          style={{ width: "435px" }}
                          columns={[
                            {
                              title: t("properties:filename"),
                              dataIndex: "filename",
                              key: "filename",
                              render: (text: any, record: any, index) => {
                                if (IsImage(values.attachments?.[index].fileName)) {
                                  return (
                                    <Popover
                                      content={
                                        <img
                                          src={`${config.apiUrl}file/${values.attachments?.[index].fileId}`}
                                          style={{ width: "300px" }}
                                          alt="Preview"
                                        />
                                      }
                                    >
                                      <div style={{ width: "100%" }}>
                                        {values.attachments?.[index].fileName}
                                      </div>
                                    </Popover>
                                  );
                                } else {
                                  return (
                                    <div style={{ width: "100%" }}>
                                      {values.attachments?.[index].fileName}
                                    </div>
                                  );
                                }
                              },
                            },
                            {
                              dataIndex: "actions",
                              align: "right",
                              render: (text: any, record: any, index) => (
                                <RowButtonSet
                                  onDelete={() => {
                                    const attachments = values.attachments?.slice(0);
                                    attachments?.splice(index, 1);

                                    setFieldValue("attachments", attachments);
                                    setState((prevState) => ({
                                      ...prevState,
                                      isChanged: true,
                                    }));
                                  }}
                                />
                              ),
                              width: "60px",
                            },
                          ]}
                          pagination={false}
                          onRow={(row, index) => ({
                            onClick: () => {
                              const url = `${config.apiUrl}file/${
                                values.attachments?.[index ?? -1].fileId
                              }`;
                              window.open(url, "_blank");
                            },
                          })}
                        />
                      </Form.Item>

                      {canUpdate && (
                        <Form.Item label={" "} colon={false}>
                          <Upload
                            name="file"
                            showUploadList={true}
                            style={{ width: "435px !important" }}
                            onRemove={(file) => {
                              const files = state.files.slice(0);
                              const index = state.files.findIndex((f) => f.name === file.name);
                              files.splice(index, 1);
                              setState((prevState) => ({
                                ...prevState,
                                files,
                                isChanged: files.length > 0,
                              }));
                            }}
                            beforeUpload={(file) => {
                              const files = state.files.slice(0);
                              files.push(file);
                              setState((prevState) => ({
                                ...prevState,
                                files,
                                isChanged: true,
                              }));
                              return false;
                            }}
                          >
                            <Button style={{ float: "right" }}>
                              <UploadOutlined /> {t("common:upload")}
                            </Button>
                          </Upload>
                        </Form.Item>
                      )}
                    </CmsModalForm>
                  </React.Fragment>
                </Spin>
              </React.Fragment>
            </Modal>
          );
        }}
      </Formik>
    );
  }
};
