import { DeleteOutlined, EditOutlined, SelectOutlined, EnterOutlined } from "@ant-design/icons";
import { Button, Popconfirm, Tooltip } from "antd";
import i18n from "i18next";
import React from "react";

interface Props {
  onSelect?: any;
  onEdit?: any;
  onAddChild?: any;
  onDelete?: any;
}

export const RowButtonSet = (props: Props) => {
  return (
    <span>
      {props.onSelect ? (
        <Tooltip title="Select">
          <Button
            type="primary"
            shape="circle"
            icon={<SelectOutlined />}
            size="small"
            style={{ margin: "0px 2px 0px 2px" }}
            onClick={() => props.onSelect()}
          />
        </Tooltip>
      ) : null}
      {props.onEdit ? (
        <Tooltip title={i18n.t("common:edit")}>
          <Button
            type="default"
            shape="circle"
            icon={<EditOutlined />}
            size="small"
            style={{ margin: "0px 2px 0px 2px" }}
            onClick={(e) => {
              e.stopPropagation();
              props.onEdit();
            }}
          />
        </Tooltip>
      ) : null}
      {props.onAddChild ? (
        <Tooltip title={i18n.t("common:add")}>
          <Button
            type="default"
            shape="circle"
            icon={<EnterOutlined />}
            size="small"
            style={{ margin: "0px 2px 0px 2px" }}
            onClick={(e) => {
              e.stopPropagation();
              props.onAddChild();
            }}
          />
        </Tooltip>
      ) : null}
      {props.onDelete ? (
        <Popconfirm
          title={i18n.t("common:confirmDelete")}
          okText={i18n.t("common:yes")}
          cancelText={i18n.t("common:no")}
          onConfirm={(e) => {
            e?.stopPropagation();
            props.onDelete();
          }}
          onCancel={(e) => e?.stopPropagation()}
        >
          <Tooltip title={i18n.t("common:delete")}>
            <Button
              danger
              type="primary"
              shape="circle"
              icon={<DeleteOutlined />}
              size="small"
              style={{ margin: "0px 2px 0px 2px" }}
              onClick={(e) => e.stopPropagation()}
            />
          </Tooltip>
        </Popconfirm>
      ) : null}
    </span>
  );
};
