import Title from "antd/lib/typography/Title";
import {
  FilterOption,
  filterOptionKey,
  getDocumentStatisticsConfig,
} from "../../utilities/configs/DocumentStatisticsConfig";
import { Checkbox, Col, ColProps, Input, Row, Select } from "antd";
import { CmsPageLoader } from "./PageComponents";
import { CmsColLayout, CmsForm, CmsFormItem, CmsFormLayout } from "./FormComponents";
import { useTranslation } from "react-i18next";

export const FiltersBox = ({
  title,
  data,
  onChange,
}: {
  title?: string;
  data: FilterBoxData[];
  onChange: (key: string, value: any) => void;
}) => {
  const { t } = useTranslation();
  const formLayout = CmsFormLayout.twocolumn;
  const colLayout: ColProps = CmsColLayout;

  const splitIndex = Math.ceil(data.length / 2);

  const renderComponents = (item: FilterBoxData) => {
    const config = getDocumentStatisticsConfig(item.key);
    switch (config.type) {
      case "list":
        return (
          <CmsFormItem
            key={config.indexKey}
            label={item.name ?? config.indexKey}
            labelAlign={"right"}
          >
            <Select
              showSearch
              allowClear={true}
              filterOption={(input, option) =>
                option?.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              value={item.defaultValue}
              onChange={(value) => onChange(item.key, value)}
            >
              {(item.data ?? config.values)?.map(
                (data) =>
                  data.value && (
                    <Select.Option key={data.value} value={data.value}>
                      {data.name}
                    </Select.Option>
                  ),
              )}
            </Select>
          </CmsFormItem>
        );
      case "input":
        return (
          <CmsFormItem
            key={config.indexKey}
            label={item.name ?? config.indexKey}
            labelAlign={"right"}
          >
            <Input onChange={(value) => onChange(config.indexKey, value)} />
          </CmsFormItem>
        );
      case "checkbox":
        return (
          <CmsFormItem
            key={config.indexKey}
            label={item.name ?? config.indexKey}
            labelAlign={"right"}
          >
            <Checkbox onChange={(value) => onChange(config.indexKey, value)} />
          </CmsFormItem>
        );
      default:
        return <></>;
    }
  };

  return (
    <>
      {title && <Title level={5}>{title}</Title>}
      <CmsPageLoader loading={false} subTitle={t("common:loadingData")}>
        <Row>
          <Col {...colLayout}>
            <CmsForm {...formLayout}>
              {data.slice(0, splitIndex).map((item) => renderComponents(item))}
            </CmsForm>
          </Col>
          <Col {...colLayout}>
            <CmsForm {...formLayout}>
              {data.slice(splitIndex).map((item) => renderComponents(item))}
            </CmsForm>
          </Col>
        </Row>
      </CmsPageLoader>
    </>
  );
};

type FilterBoxData = {
  key: filterOptionKey;
  data?: FilterOption[];
  defaultValue?: string;
  name?: string;
};
