import { Button, Checkbox, Form, Layout, Modal, Select, TreeSelect } from "antd";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FieldLink } from "../../../models/templates/FieldLink";
import { convertGroupsForTreeSelect } from "../../../utilities/GroupHelper";
import { validateRequired } from "../../../utilities/Validators";
import { CmsModalForm } from "../../common/FormComponents";
import { CmsPageLoader } from "../../common/PageComponents";
import { useQueryGroups } from "../../../queries/groups/lists";
import { GroupType } from "../../../queries/groups/group-types";
import { useQueryFields } from "../../../queries/fields/lists";
import { getField } from "../../../queries/fields/detail";

export const FieldLinkDetailsModal = (props: {
  visible: boolean;
  fieldLink: FieldLink;
  onClose(): void;
  onSave(fieldLink: FieldLink): void;
}) => {
  const { t } = useTranslation();
  const [selectedGroupId, setSelectedGroupId] = useState<string | undefined>(undefined);

  const { data: fieldGroups, isLoading: isLoadingFieldGroups } = useQueryGroups(GroupType.Field);
  const { data: fields, isLoading: isLoadingFields } = useQueryFields(selectedGroupId);

  const setGroup = async (fieldId: string) => {
    const field = await getField(fieldId);
    const groupId = field.groupId;
    setSelectedGroupId(groupId);
  };

  useEffect(() => {
    if (props.fieldLink) {
      if (props.fieldLink.fieldId) {
        setGroup(props.fieldLink.fieldId);
      } else {
        setSelectedGroupId(undefined);
      }
    }
  }, [props.fieldLink, getField]);

  const validate = (fieldLink: FieldLink) => {
    const errors: any = {};

    const fieldIdResult = validateRequired(fieldLink.fieldId, t("entities:field"));
    if (fieldIdResult != null) {
      errors.fieldId = fieldIdResult;
    }

    return errors;
  };

  return (
    <Formik
      initialValues={props.fieldLink}
      enableReinitialize={true}
      validate={validate}
      onSubmit={(fieldLink) => {
        props.onSave(fieldLink);
      }}
    >
      {(formikProps) => {
        const { values, handleChange, handleSubmit, setFieldValue, errors, isValid } = formikProps;

        return (
          <Modal
            width={700}
            title={t("entities:field")}
            open={props.visible}
            footer={[
              <Button id="btnCancel" key="cancel" onClick={props.onClose}>
                {t("common:cancel")}
              </Button>,
              <Button
                id="btnSave"
                key="save"
                type="primary"
                disabled={!isValid}
                onClick={() => {
                  handleSubmit();
                }}
              >
                {t("common:save")}
              </Button>,
            ]}
            onCancel={props.onClose}
          >
            <CmsPageLoader
              loading={isLoadingFieldGroups || isLoadingFields}
              subTitle={t("common:loadingData")}
            >
              <Layout style={{ background: "#fff" }}>
                <div key="fieldLinkDetails">
                  <CmsModalForm>
                    <Form.Item
                      label={t("entities:field")}
                      validateStatus={!errors.fieldId ? "success" : "error"}
                      help={errors.fieldId}
                      required={true}
                    >
                      <React.Fragment>
                        <TreeSelect
                          id="groupId"
                          treeDefaultExpandAll={true}
                          style={{ width: "40%" }}
                          placeholder={t("properties:group")}
                          onChange={(groupId) => {
                            setFieldValue("groupId", groupId);
                            setFieldValue("fieldId", undefined);
                            setSelectedGroupId(groupId);
                          }}
                          value={selectedGroupId}
                          treeData={convertGroupsForTreeSelect(fieldGroups ?? [])}
                        />
                        &nbsp;
                        <Select
                          showSearch
                          id="fieldId"
                          style={{ width: "58%" }}
                          placeholder={t("entities:field")}
                          filterOption={(input, option) =>
                            option?.props.children
                              .toString()
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          onChange={(fieldId: string) => {
                            const field = fields?.find((field) => field.id === fieldId);
                            if (field) {
                              setFieldValue("fieldName", field.name);
                            }
                            setFieldValue("fieldId", fieldId);
                          }}
                          value={values.fieldId}
                        >
                          {fields?.map((value, index) => (
                            <Select.Option key={`${index}`} value={value.id}>
                              {value.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </React.Fragment>
                    </Form.Item>
                    <Form.Item label={t("properties:exportable")}>
                      <Checkbox
                        id="exportable"
                        checked={values.exportable}
                        onChange={handleChange}
                      />
                    </Form.Item>
                    <Form.Item label={t("properties:required")}>
                      <Checkbox
                        id="isRequired"
                        checked={values.isRequired}
                        onChange={handleChange}
                      />
                    </Form.Item>
                  </CmsModalForm>
                </div>
              </Layout>
            </CmsPageLoader>
          </Modal>
        );
      }}
    </Formik>
  );
};
