import Config from "../../Config";
import { GenerateOptions } from "./types";

const generateOptions: GenerateOptions = ({
  method,
  path,
  baseUrl,
  isFile = false,
  isJson = true,
  isTotalCount = false,
  isUpload = false,
  error,
  rawResponse = false,
  ...options
}) => {
  const apiUrl = Config.apiUrl;
  const headers = options.headers || {};

  const query: ConstructorParameters<typeof URLSearchParams>[0] = options.query
    ? { ...(options.query as Record<string, string>) }
    : {};

  const queries = options.query ? `?${new URLSearchParams(query)}` : "";
  let body = method !== "GET" ? ((options.body || {}) as BodyInit) : undefined;

  const isNotFileUpload = !isFile || !isUpload;
  if (isNotFileUpload) {
    headers["Content-Type"] = "application/json";

    if (body) {
      body = JSON.stringify(body);
    }
  }

  headers["Authorization"] = `Bearer ${sessionStorage.getItem("token")}`;

  return {
    path: `${apiUrl}${path}${queries}`,
    options: {
      method,
      headers,
      body,
    },
    isTotalCount,
    isFile,
    isUpload,
    isJson,
    errorConfig: error,
    rawResponse,
  };
};

export default generateOptions;
